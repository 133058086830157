@tagHeight: 16px;
@tagRadius: 3px;

.tagsBg {
  display: flex;
  flex-direction: row;
  gap: 3px;
  flex-wrap: nowrap;
}

.tagImg {
  width: auto;
  border-radius: @tagRadius;
  height: @tagHeight;
  flex-shrink: 0;
  background-color: transparent;
}

.tagItem {
  position: relative;
  box-sizing: border-box;
  border-radius: @tagRadius;
  height: @tagHeight;
  line-height: @tagHeight;
  padding: 0 4px;
  font-size: var(--adm-font-size-2);
  width: max-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;

  &[data-single='true']&[data-style='2'],
  &[data-single='false'],
  &[data-skin='true'] {
    line-height: calc(@tagHeight - 2px);
    border-width: 1px;
    border-style: solid;
    border-color: var(--tag-color);
    color: var(--tag-color);
  }

  &:first-of-type&[data-single='false']:not(&[data-skin='true']),
  &[data-single='true']&[data-style='1']:not(&[data-skin='true']) {
    line-height: @tagHeight;
    border-width: 0px;
    background-color: var(--tag-color);
    padding-right: 6px;
    color: #ffffff;

    &:after {
      content: '';
      width: 6px;
      height: 6px;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: -3px;
    }
  }
}
