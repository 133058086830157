.address-card-container {
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  .address-card-content {
    box-sizing: border-box;
    width: calc(~'100vw - 30px');
    height: 107px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-radius: 10px;
    background-color: #ffffff;

    .info {
      .address {
        display: flex;
        align-items: center;
        height: 20px;
        line-height: 20px;
        margin-bottom: 10px;
        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        span.address-detail {
          color: #222222;
          font-weight: 600;
          font-size: 18px;
        }
        .tag {
          display: inline-block;
          margin-left: 10px;
          height: 18px;
          line-height: 18px;
          color: #e64c38;
          padding: 0 10px;
          font-size: 12px;
        }
      }
      .detail {
        height: 20px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #666666;
        font-weight: 400;
        font-size: 14px;
      }
      .user {
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        .name {
          margin-right: 10px;
        }
      }
    }
    .btn {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .delete {
    height: 16px;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    span {
      color: #db0007;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

// 自定义 antd-m style
// .mcd-dialog-container {
//   .adm-dialog-action-row {
//     border: none;
//   }
// }
