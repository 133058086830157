.footer-container {
  position: fixed;
  left: 0px;
  bottom: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  padding: 15px 15px;
  background-color: #fff;
  .btn {
    display: block;
    width: 100%;
    font-weight: bold;
  }
}
