@import '../../../../../../styles/variable.less';

.productItem {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 15px 12px 8px;
  gap: 8px;

  &[data-midmode='true'] {
    margin: 0 6px 12px;
    padding: 20px 9px 20px 2px;
    // padding: 6px 15px 6px 8px;
    border-radius: 8px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left center;
  }

  &[data-displaymode='0'] {
    color: var(--adm-color-white);
  }

  &:not(&[data-midmode='true'])::after {
    content: '';
    left: 16px;
    bottom: 0;
    position: absolute;
    width: calc(100% - 32px);
    border-bottom: 1px solid #f3f3f3;
  }
}

.productItemImg {
  width: 130px;
  min-height: 98px;
  height: 100%;
}

.productItemContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  padding: 8px 0;
}

.productItemContentTitle {
  font-size: var(--adm-font-size-6);
  font-weight: @font-weight-bold;
  line-height: 1.2;
}

.productItemContentDesc {
  color: #969696;
  font-weight: 400;
  font-size: var(--adm-font-size-2);
  line-height: 1.5;
  margin-top: 6px;
}

.productItemContentMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productItemContentPrice {
  font-size: var(--adm-font-size-3);
  margin-right: 2px;
  line-height: 1;
}

.productItemContentPriceNum {
  font-size: 1.428rem;
  font-weight: @font-weight-bold;
  line-height: 0;
  color: #212223;
}

.cardTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 5px;
}

.cardTagBg {
  display: flex;
  flex-direction: row;
}

.cardTagIcon {
  height: 16px;
  background-color: #363638;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.cardTagText {
  height: 16px;
  background-color: #f1dfa5;
  font-family: PingFangSC-Medium;
  font-size: var(--adm-font-size-2);
  color: #4f4134;
  line-height: 16px;
  padding-left: 2px;
  padding-right: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
