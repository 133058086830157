
.home_activity_position_view {
  position: relative;
  overflow: hidden;
  border-radius: 10Px;
  width: 100%;
  height: 100%;
  .home_activity_position_first {
    width: 100%;
    height: 100%;
  }
  .home_activity_position_second {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 50Px);
    transition: all 80ms ease-out;
  }
  .home_activity_position_third {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 100Px);
    transition: all 80ms ease-out;
  }
}