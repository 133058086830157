.address-info-container {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  .base-info {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
    background-image: url(https://img.mcd.cn/mini/main/images/confirm_map.png);
    background-size: 100%;
    .left {
      // flex-grow: 1;
      .name {
        color: #222222;
        font-weight: 600;
        font-size: 18px;
      }
      .address {
        color: #222222;
        font-weight: 300;
        font-size: 14px;
      }
      .shop_name_text {
        font-size: 18px;
        color: #222222;
        font-family: PingFangSC-Semibold;
        font-weight: bold;
      }
      .no_address_bg {
        margin-top: 5px;
        margin-bottom: 5px;
        height: 29px;
        display: flex;
        justify-content: center;
        padding: 0px 10px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        background-image: url(https://img.mcd.cn/mini/main/images/order_confirm_no_addrdess_pop.png);
        .no_address_text {
          margin-top: 8px;
          color: #c1874d;
          font-size: 12px;
          font-family: PingFangSC-Regular;
        }
      }
    }
    .right {
      position: relative;
      flex-basis: 50px;
      margin-left: 15px;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .btn-wrapper {
        position: absolute;
        left: 50%;
        top: -14px;
        width: 100%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        .btn {
          height: 20px;
          line-height: 20px;
          color: #7e5122;
          font-weight: 500;
          font-size: 10px;
          padding: 0 5px;
          border-radius: 10px;
          background-color: #ffe1a0;
          white-space: nowrap;
          transform: scale(0.8);
        }
      }
    }
  }
  .delivery-time-container {
    padding: 0 20px;
    .delivery-time-content {
      padding-top: 20px;
      border-top: 0.5px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        color: #222222;
        font-weight: 600;
        font-size: 16px;
      }
      .time {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          color: #f6ab01;
          font-weight: 400;
          font-size: 14px;
          margin-right: 5px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
