.order-item-content {
  margin-bottom: 10px;
  border-radius: 10px;
  .tip {
    height: 35px;
    background-color: #fff8e4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    z-index: 11;

    .tip_text {
      padding-left: 15px;
      padding-right: 15px;
      color: rgba(34, 34, 34, 1);
      font-size: 12px;
      text-align: center;
      font-family: PingFangSC-Regular;

      .tip_red {
        color: rgba(219, 0, 7, 1);
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        font-family: DINAlternate-Bold;
      }
    }
  }
  .order-item-container {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 15px 20px;
    margin-bottom: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .store {
        color: #222222;
        font-weight: 600;
        font-size: 14px;
      }
      .status {
        color: #666666;
        font-weight: 600;
        font-size: 12px;
      }
    }
    .product-wrapper {
      display: flex;
      height: 80px;
      &.one {
        height: 60px;
      }
      .product-list {
        flex: 1;
        overflow-x: scroll;
        display: flex;
        margin-right: 20px;
        &::-webkit-scrollbar {
          display: none;
        }
        .item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex-basis: 80px;
          flex-shrink: 0;
          margin-right: 5px;
          img {
            width: 100%;
            height: 60px;
            object-fit: cover;
          }
          .name {
            width: 100%;
            color: #222222;
            font-weight: 500;
            font-size: 12px;
          }
          font-size: 12px;
        }
        &.one {
          .item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-basis: 100%;
            margin-right: 0;
            img {
              width: 60px;
              height: 45px;
              object-fit: cover;
              margin-right: 5px;
            }
            .name-wrapper {
              flex: 1;
              .name {
                color: #222222;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 5px;
              }
              .name-detail {
                color: #999999;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
              }
            }
          }
        }
      }
      .total {
        flex-basis: 70px;
        display: flex;
        flex-direction: column;
        text-align: right;
        .money {
          color: #222222;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          .symbol {
            font-weight: 400;
            font-size: 12px;
          }
          .val {
            font-family: SpeedeeApp-Bold;
          }
        }
        .count {
          color: #999999;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      .adm-button {
        padding: 5px 15px !important;
        border-radius: 14px !important;
        font-size: 12px !important;
      }

      &.hasBtn {
        padding-top: 10px;
        border-top: dashed 0.5px #e8e8e8;
        margin-top: 15px;
      }

      .order_list_card_btn_normal {
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        margin-left: 10px;
        background-color: white;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          /* 把父视图设置为relative，方便定位*/
          top: 0;
          left: 0;
          width: 200%;
          height: 200%;
          transform: scale(0.5);
          transform-origin: 0 0;
          box-sizing: border-box;
          border-radius: 28px;
          border: 1px solid #666;
        }

        .order_list_card_btn_text_normal {
          color: #222;
          text-align: center;
          font-family: PingFangSC-Regular;
        }
      }
    }
  }
}

.cancel-wrapper .adm-dialog-action-row button {
  &:last-child {
    background-color: #ffbc0d;
  }
}
