.tags-container {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  height: 47px;
  padding: 0 10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .tags-content {
    white-space: nowrap;
    .item {
      display: inline-block;
      height: 32px;
      padding: 0 13px;
      line-height: 32px;
      border-radius: 5px;
      margin: 0 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &.selected {
        border: 1px solid #ffbc0d;
      }
    }
  }
}
