@import '../../../../../styles/variable.less';

.wrapper {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.titleImg {
  width: 61px;
  height: 15px;
  margin-left: 12px;
}

.titleWrapper {
  padding-top: 18px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleNum {
  height: 21px;
  line-height: 21px;
  margin-left: 4px;
  padding: 0 5px;
  border-radius: 10px;
  background-color: rgba(255, 247, 219, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--adm-font-size-2);
  color: #511000;
}

.titleNumCount {
  font-size: var(--adm-font-size-3);
  font-weight: bold;
}

.titleNumIcon {
  margin-left: 3px;
  width: 13px;
  height: 13px;
}

@item-gap: 6px;

.content {
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding: 0 0 @item-gap @item-gap;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &[data-total='1'],
  &[data-total='2'],
  &[data-total='3'] {
    overflow-x: hidden;
    padding: 0 @item-gap @item-gap;
  }

  &[data-total='2'] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: @item-gap;
    grid-row-gap: 0px;
  }
  &[data-total='3'] {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: @item-gap;
    grid-row-gap: 0px;
  }
}

.item {
  flex: 1;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;

  &[data-more='true'] {
    width: calc(31% - 6px);
    flex: 0 0 calc(31% - 6px);
    margin-right: 6px;
  }
}

.itemLeft {
  margin: 0px;
  width: 8px;
  height: 100%;
  align-self: center;
}

.itemRight {
  flex: 1;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;

  &[data-total='1'] {
    width: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px 5px 22px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &[data-total='2'] {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    padding: 0 6px;
  }
}

.itemImg {
  width: 78px;
  height: 78px;
  flex: 0 0 78px;
  margin-top: 8px;
  align-self: center;

  &[data-total='1'] {
    width: 72px;
    height: 72px;
    flex: 0 0 72px;
    margin: 0;
  }
  &[data-total='2'] {
    width: 58px;
    flex: 0 0 58px;
    height: auto;
    margin: 0;
  }
}

.itemTitleView {
  box-sizing: border-box;
  font-size: var(--adm-font-size-4);
  margin-top: 2px;
  padding-left: 6px;

  &[data-total='1'] {
    flex: 1;
    min-width: 0;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: var(--adm-font-size-8);
    font-weight: bold;
  }

  &[data-total='2'] {
    margin-left: 6px;
    font-size: var(--adm-font-size-4);
  }
}

.itemTitleText {
  white-space: normal;

  &[data-ellipsis='true'] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.itemTimeRange {
  font-size: var(--adm-font-size-2);
  font-weight: @font-weight;
  color: @text-secondary;
  margin-top: 8px;
}

.itemSignleBtn {
  padding: 0 8px;
  box-sizing: border-box;
  margin-left: @item-gap;
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  text-align: center;
  font-size: var(--adm-font-size-2);
  background-color: @primary-color;
  color: @text-color;
  font-weight: @font-weight-bold;

  &[data-disabled='true'] {
    background-color: @disabled-color;
    color: @text-tertiary;
    font-weight: @font-weight;
  }
}

.itemAddBtn {
  width: 22px;
  height: 22px;
  flex: 0 0 22px;

  &[data-absolute='true'] {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}

.showMoreBtn {
  height: 117px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
}

.showMoreBtnText {
  color: #db0007;
  font-family: 'PingFangSC-Medium';
  font-size: var(--adm-font-size-3);
  width: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 26.5px;
}

.showMoreBtnIcon {
  margin-top: 0px;
  width: 12px;
  height: 12px;
  margin-left: 10px;
}
