.user_persional {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  .detail-sv {
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    padding-top: 70px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .avatar {
    display: block;
    width: 105px;
    height: 105px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 25px;
    .head-img {
      height: 100%;
      width: 100%;
      border-radius: 105px;
      overflow: hidden;
    }
    .head-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .item {
    display: flex;
    height: 57px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
    margin-left: 20px;
    margin-right: 20px;
    .label {
      font-size: 14px;
      color: #999;
    }
    .value {
      padding-right: 20px;
      position: relative;
      height: 57px;
      line-height: 20px;
      font-size: 14px;
      color: #222;
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .nickName {
      width: calc(100vw - 130px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .valueSingle {
      padding-right: 20px;
      width: 240px;
    }
    // .valueHint {
    //   padding-left: 10px;
    //   padding-right: 8px;
    //   position: relative;
    //   height: 28px;
    //   line-height: 20px;
    //   font-size: 12px;
    //   color: #222;
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;
    //   background: #f7b136;
    //   border-radius: 14px;
    // }
    .icon {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      right: 0px;
      top: 21px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
