@import '../../../../../../styles/variable.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: var(--adm-font-size-6);
}

.priceSymbol {
  margin-right: var(--symbol-gap, 2px);
  font-size: 0.785em;
  line-height: 1.2;
}

.priceNum {
  font-family: SpeedeeApp-Bold;
  font-size: 1.285em;
  line-height: 1;

  &[data-large='true'] {
    font-size: 1.142em;
  }
}

.priceLast {
  font-family: SpeedeeApp-Bold;
  font-size: 0.9em;
  line-height: 1;

  &[data-large='true'] {
    font-size: 1em;
  }
}

.priceFrom {
  font-size: 0.714em;
  margin-left: 3px;
}

.priceSeparate {
  font-size: 0.857em;
  text-decoration: line-through;
  margin-left: 5px;
  color: #bcbcbc;
}
