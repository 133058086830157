@import '../../../../../styles/variable.less';

.name {
  font-size: var(--adm-font-size-3);
  color: @text-secondary;
}

.itemBox {
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  overflow: hidden;
}

.item {
  flex: 1;
  height: 100%;
  padding: 0 10px;
  position: relative;
  box-sizing: border-box;
  font-size: var(--adm-font-size-5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid rgba(102, 102, 102, 0.27);
  border-radius: 5px;

  &:not(&:first-of-type) {
    margin-left: 12px;
  }

  &[data-selected='true'] {
    border: 2px solid @primary-color;
    font-weight: bold;
  }

  &[data-disbaled='true'] {
    background-color: #fafafa;
    color: #bbbbbb;
  }
}

.itemText {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  &[data-spread='true'] {
    max-width: 80%;
  }
}

.itemSpread {
  color: #cbaa70;
  font-weight: bold;
}

.itemSoldOut {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 4px;
  height: 13px;
  background-color: rgb(204, 204, 204);
  border-bottom-left-radius: 5px;
  border-top-right-radius: 4px;
  color: #ffffff;
  font-size: var(--adm-font-size-1);
  line-height: 13px;
  text-align: center;
}
