@import '../variable.less';

.adm-modal {
  .adm-modal-body:not(.adm-modal-with-image) {
    padding-top: 25px;
  }

  &-content {
    font-size: var(--adm-font-size-8);
    padding: 5px 15px 20px;
    font-weight: @font-weight-light;
    text-align: center;
  }

  &-footer {
    padding: 5px 15px 30px;

    &.adm-space {
      flex-direction: row;

      .adm-space-item {
        flex: 1;
        margin-bottom: 0;

        &:only-of-type {
          .adm-button {
            font-weight: @font-weight-bold;
          }
        }

        &:not(&:first-of-type) {
          margin-left: 10px;
        }

        // reset
        .adm-button.adm-button-large,
        .adm-modal-button:not(.adm-modal-button-primary) {
          line-height: 22px;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        // reset
        .adm-button-fill-none {
          border: 1px solid @text-tertiary;
        }
      }
    }

    .adm-modal-button {
      font-size: var(--adm-font-size-8);
      --border-radius: 1000px;
    }
  }
}
