@import '../../../styles/variable.less';

.swiperItemImg {
  height: 16rem;
}

.swiperItemVideoWrapper {
  height: 16rem;
}

.swiperItemVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.bannerVideoWrapper {
  position: relative;
}

.bannerVideoPlatBtn {
  position: absolute;
  bottom: 32px;
  left: 16px;
  padding: 0 4px;
  height: 24px;
  border-radius: 12px;
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: @primary-color;
}

.bannerVideoPlatBtnIcon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.bannerVideoPlatBtnTxt {
  font-size: var(--adm-font-size-3);
}
