.city-btn-wrapper {
  display: inline-block;
  padding: 6px 15px;
  margin-left: 10px;
  margin-bottom: 12px;
  background-color: #f2f2f2;
  color: #666666;
  font-size: 14px;
  border-radius: 6px;
}
