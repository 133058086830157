@import '../../../../styles/variable.less';

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.side {
  position: sticky;
  top: calc(var(--top, 114) * 1px);
  height: 100%;
  height: calc(@100vh - calc(var(--top, 114) * 1px));
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.main {
  flex: 1;
}

.menubarItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menubarItemImg {
  width: 35px;
  height: 35px;
}

.menubarItemTitle {
  font-size: var(--adm-font-size-2);
  font-weight: @font-weight;
  text-align: center;
  white-space: pre;
}

.categoeyName {
  padding: 12px 12px 4px;
  line-height: 1;
  font-size: var(--adm-font-size-4);
  color: @text-secondary;
  font-weight: 500;
}

.categoeySelector {
  padding: 8px 12px;
  position: sticky;
  top: calc(var(--top, 114) * 1px);
  background: #ffffff;
  z-index: 1;
}
