.content-logout {
  min-height: 800px;
}

.logout-desc {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  color: #222222;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  line-height: 23px;
}

.verify-phone-desc {
  display: flex;
  margin-top: 40px;
  font-size: 16px;
  margin-left: 20px;
  color: #222222;
  font-family: 'PingFangSC-Semibold';
}

.verify-phone {
  flex-direction: row;
  display: flex;
  margin-top: 23px;
  height: 41px;
}

.verify-phone-code {
  margin-left: 20px;
  margin-top: 2px;
  color: #222222;
  font-size: 14px;
  font-family: 'PingFangSC-Semibold';
}

.verify-phone-phone {
  margin-left: 20px;
  margin-top: 2px;
  color: #222222;
  font-size: 14px;
  font-family: 'PingFangSC-Semibold';
}

.logout-bottom {
  background-color: #fff;
  padding: 30px 40px;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.1);
  /* #ifndef rn  */
  border-top-style: solid;
  /* #endif */
  .adm-button-primary {
    background-color: rgb(217, 0, 7);
    border-color: rgb(217, 0, 7);
    color: white;
  }
}

.verify-tip {
  flex-direction: row;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.verify-tip-label {
  flex: 1;
  line-height: 22px;
  color: #999999;
  font-size: 12px;
  font-family: 'PingFangSC-Semibold';
}

.verify-tip-phone {
  font-size: 12px;
  color: #417eff;
  font-family: 'PingFangSC-Semibold';
}

.verify-phone-label {
  margin-left: 20px;
  margin-top: 2px;
  width: 56px;
  color: #999999;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
}
.verify-reason {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 16px;
}

.verify-reason-label {
  color: #222222;
  font-size: 16px;
  font-family: 'PingFangSC-Semibold';
}

.verify-reason-radio {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.verify-reason-text {
  margin-left: 10px;
  font-size: 14px;
  color: #222222;
  font-family: 'PingFangSC-Regular';
}
