.index {
  width: 100%;
  padding-top: 50px;

  .content-view {
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  .adm-tabs-header {
    border-bottom: solid 0px var(--adm-color-border);
  }
  .adm-tabs-tab {
    padding: 8px 0 0px;
  }
  .adm-space-horizontal.adm-space-wrap {
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
  }
  .group-coupon-index-outside {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    flex-shrink: 0;
    position: relative;

    .group-coupon-index {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 90%;

      .group-coupon-bg {
        height: 123px;
        width: 8px;
        flex-basis: 8px;
      }

      .group-coupon-content {
        position: relative;
        height: 122px;
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.05);
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-left: white;

        .group-coupon-content-img {
          width: 100px;
          height: 100px;
        }

        .group-coupon-content-text {
          display: flex;
          flex: 1;
          flex-direction: column;
          .group-coupon-label {
            height: 16px;
            overflow: hidden;
            line-height: 14px;
            margin-bottom: 3px;
            .tag-text {
              border-style: solid;
              border-radius: 3px;
              border-width: 1px;
              margin-left: 1px;
              margin-right: 5px;
              margin-bottom: 5px;
              padding-left: 5px;
              padding-right: 5px;
              font-size: 9px;
              font-family: PingFangSC-Medium;
              font-weight: bold;
              float: left;
            }
            .tag-img {
              width: 100%;
              height: 20px;
              float: left;
            }
          }

          .group-coupon-title {
            color: #222222;
            font-family: PingFangSC-Medium;
            font-weight: bold;
            font-size: 16px;
            margin-top: 1px;
            width: calc(90vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .group-coupon-subtitle {
            color: #575757;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            margin-top: 1px;
            width: calc(90vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .group-coupon-date {
            color: #666666;
            font-family: PingFangSC-Light;
            font-size: 10px;
            margin-top: 1px;
            width: calc(90vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .group-coupon-count {
            color: #666666;
            font-size: 10px;
            width: max-content;
            font-weight: 300;
            margin-top: 1px;
          }

          .group-coupon-detail {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;

            .group-coupon-detail-text {
              color: #000000;
              font-family: PingFangSC-Regular;
              font-size: 10px;
            }

            .group-coupon-detail-arrow {
              width: 12px;
              height: 12px;
            }
          }
          .discount-title {
            font-size: 12px;
            color: #575757;
            .discount-price {
              font-family: SpeedeeApp-Bold;
              color: #d62223;
              font-size: 14px;
              margin: 0 1px;
            }
          }
        }

        .group-coupon-unselected {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          border-radius: 18px;
          border-width: 0.5px;
          border-color: #737373;
          border-style: solid;
        }

        .group-coupon-selected {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        .group-coupon-prepaid {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 48px;
          height: 42px;
        }
      }

      .group-coupon-left {
        position: absolute;
        width: 60%;
        height: 123px;
      }

      .group-coupon-right {
        position: absolute;
        width: 30%;
        height: 123px;
        right: 0px;
      }

      .group-coupon-type-angle {
        position: absolute;
        right: 1px;
        bottom: 28px;
        height: 12px;
        width: 12px;
        background-color: #f2f2f2;

        .group-coupon-type-angle-inside {
          height: 12px;
          width: 12px;
          border-bottom-right-radius: 6px;
          background-color: white;
        }
      }

      .group-coupon-type {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 6px;
        background-color: #f2f2f2;
        padding: 0 5px;
        right: 0;
        bottom: 0;

        .group-coupon-type-image {
          width: 18px;
          height: 18px;
        }
      }
    }

    .group-coupon-bottom {
      display: flex;
      flex-direction: row;
      height: 8px;
      width: 86%;

      .group-coupon-bottom-img {
        width: 15px;
        height: 8px;
      }

      .group-coupon-bottom-mid {
        flex: 1;
        height: 8px;
      }
    }
  }
}
