.order-container {
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .order-header {
    // background-color: #eeeeee;
  }
  .content {
    min-height: 100%;
    background-image: linear-gradient(#fff 50px, #eee 100px);
    .adm-tabs-header {
      position: sticky;
      top: 0px;
      background-color: #fff;
      z-index: 9;
      border: none;
      .adm-tabs-tab-list {
        .adm-tabs-tab-line {
          display: none;
        }
        padding-left: 15px;
        .adm-tabs-tab-wrapper {
          margin-right: 15px;
          padding: 0;
          .adm-tabs-tab {
            color: #222222;
            font-weight: 400;
            font-size: 14px;
            .tab-item {
              display: inline-block;
              position: relative;
            }
            .bg {
              // display: none;
            }
            &.adm-tabs-tab-active {
              font-weight: 500;
              font-size: 16px;
              .bg {
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 5px;
                border-radius: 27px 0px;
                background: linear-gradient(90deg, rgba(252, 187, 24, 1) 0%, rgba(252, 187, 24, 1) 100%);
              }
            }
          }
        }
      }
    }
  }
}
