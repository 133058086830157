.pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay-info {
    padding: 60px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 14px;
      color: #666;
      margin-bottom: 20px;
    }
    .amount {
      font-weight: bold;
      .symbol {
        font-size: 20px;
      }
      .val {
        font-family: 'SpeedeeApp-Bold';
        font-size: 28px;
        .int {
          font-size: 40px;
        }
        .point {
          font-size: 28px;
        }
      }
    }
  }
  .divider {
    margin: 0 20px;
    width: calc(~'100vw - 40px');
    height: 0.5px;
    background-color: #222222;
    opacity: 0.1;
  }

  .channel {
    padding-top: 30px;
    font-size: 14px;
    color: #666;
    margin-bottom: 30px;
  }

  .adm-radio {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    svg {
      path {
        fill: #000;
      }
    }
  }

  .name-wrapper {
    display: inline-flex;
    align-items: center;
    .name {
      margin: 0 20px 0 12px;
    }
    .prefix-icon,
    .post-icon {
      width: 34px;
      height: 34px;
    }
  }

  .btn-wrapper {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    button {
      width: 100%;
      font-weight: bold;
    }
  }
}
