.wrapper {
  position: relative;

  &[data-loading='true'] {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
}

.loadingWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
