@import '../../../../../styles/variable.less';

.specialTitle {
  font-size: var(--adm-font-size-4);
  color: @text-tertiary;
  padding-top: 25px;
}

.specialWrapper {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  margin-top: 10px;
  grid-row-gap: 15px;
  margin-bottom: 20px;
}

.specialItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specialItemImgView {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90px;
  height: 90px;
  border: 2px solid rgba(204, 204, 204, 0.5);
  box-sizing: border-box;
  border-radius: 50%;

  &[data-checked='true'] {
    border-color: rgba(255, 188, 13, 1);
  }
}

.specialItemImgViewImg {
  width: 74px;
  height: auto;
  border-radius: 30%;
}

.specialItemImgViewIcon {
  position: absolute;
  z-index: 2;
  width: 24px;
  height: 24px;
  top: 1px;
  right: -8px;
}

.specialItemName {
  margin-top: 5px;
  text-align: center;
  font-size: var(--adm-font-size-3);
  color: @text-secondary;

  &[data-checked='true'] {
    color: @text-color;
    font-weight: @font-weight-bold;
  }
}

.specialItemSoldOutMask {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 0;
  opacity: 0.4;
  background-color: #eaeaea;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 90px;
}
