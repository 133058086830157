.order-container {
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.order_list_swiper_empty_bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  z-index: 10;
  background-color: #eee;

  .order_list_swiper_footer_button {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 134px;
    padding-top: 15px;
    z-index: 11;

    .order_list_swiper_footer_button_text {
      display: block;
      border: 1px solid rgba(217, 217, 217, 1);
      border-radius: 14px;
      width: 102px;
      line-height: 28px;
      text-align: center;
      align-items: center;
      color: rgba(34, 34, 34, 100);
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      padding: 0 15px;
    }
  }

  .order_list_swiper_empty_img {
    margin-top: 84px;
    height: 180px;
    width: 180px;
  }

  .order_list_swiper_empty_text {
    margin-top: 20px;
    color: rgba(34, 34, 34, 1);
    font-size: 16px;
    text-align: center;
    font-family: PingFangSC-Light;
    font-weight: lighter;
  }

  .order_list_swiper_empty_btn {
    margin-top: 20px;
    width: 185px;
    height: 42px;
    border-radius: 21px;
    background-color: rgba(255, 188, 13, 1);
    align-items: center;
    justify-content: center;
    display: flex;

    .order_list_swiper_btn_title {
      color: rgba(34, 34, 34, 1);
      font-size: 18px;
      text-align: center;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
    }
  }
}
