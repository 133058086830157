@import '../../../../styles/variable.less';

.popupBody {
  height: 80%;
  box-sizing: border-box;
  padding-top: 82px;
  display: flex;
  flex-direction: column;
}

.productImgWrapper {
  position: absolute;
  top: -95px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImg {
  height: 185px;
  width: auto;
}

.productName {
  font-size: 20px;
  font-weight: @font-weight-bold;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 20px;
}

.content {
  box-sizing: border-box;
  padding: 0 20px 16px;
  flex: 1;
  overflow-y: auto;
}

.specialTitle {
  font-size: var(--adm-font-size-4);
  color: @text-tertiary;
  margin-top: 25px;
}

.footer {
  padding: 15px 20px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &[data-supportsafearea='true'] {
    padding-bottom: var(--safe-area-inset-bottom);
  }
}

.footerBtnsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.restBtn {
  font-size: var(--adm-font-size-8) !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  margin-right: 16px;
}

.comfirmBtn {
  font-size: var(--adm-font-size-8);
  flex: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  height: 42px;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
}

.btnPrice {
  margin-right: 4px;
}
