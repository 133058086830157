.place-card-container {
  box-sizing: border-box;
  width: 335px;
  margin: 0 20px;
  width: calc(~'100vw - 40px');
  padding: 11px 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info {
    .address {
      color: #222222;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    .streetAddress {
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .btn {
    color: #0086d6;
    font-weight: 300;
    font-size: 14px;
    margin-left: 10px;
  }
}
