@import '../../../../styles/variable.less';

.container {
  font-size: var(--adm-font-size-8);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.storeName {
  font-weight: @font-weight-bold;
  display: flex;
  max-width: 100%;
}

.storeNameText {
  flex: 1;
  max-width: max-content;
  word-break: break-all;
  white-space: wrap;
}

.switchAddressIcon {
  height: 12px;
  width: 12px;
  margin-top: 5px;
  margin-left: 6px;
}

.orderType {
  display: flex;
  align-items: center;
  font-size: var(--adm-font-size-4);
  margin-top: 6px;
}

.orderTypeText {
  font-weight: @font-weight-bold;
}

.orderTypeDistance {
  font-weight: @font-weight;
  color: @text-tertiary;

  &::before {
    content: '|';
    margin-left: 4px;
    margin-right: 4px;
  }
}

.orderTypeImg {
  width: 15px;
  height: 15px;
  align-self: center;
  margin-right: 4px;
}

.daypartWrapper {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 77px;
  height: 56px;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  background-image: url(https://img.mcd.cn/mini/main/images/store_step_tip.png);
}

.daypartTitle {
  flex: 1;
  margin-top: 5px;
  text-align: center;
  line-height: 1em;
  font-size: var(--adm-font-size-8);
  font-family: PingFangSC-Medium;
  font-weight: bold;
  color: #444444;

  &[data-ordertype='2'] {
    text-align: right;
    margin-right: 4px;
  }

  &[data-active='false'] {
    font-size: var(--adm-font-size-5);
  }
}

.daypartSwitchIcon {
  width: 12px;
  height: 11px;
  margin-top: 6px;
  margin-right: 12px;
}
