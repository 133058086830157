.order-confirm-footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px 34px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  .footer-bar-content {
    position: relative;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3a3a3a;
    border-radius: 21px;
    .total {
      display: flex;
      align-items: baseline;
      padding-left: 20px;
      .total-price {
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        .price {
          display: flex;
          align-items: baseline;
          .label {
            margin-right: 3px;
          }
          .num {
            margin-left: 3px;
            font-weight: 700;
            font-size: 24px;
            font-family: SpeedeeApp-Bold;
            .decimal {
              font-size: 13px;
            }
          }
        }
        .delivery {
          margin-left: 10px;
          font-size: 10px;
          color: #999;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .discount {
            color: #ffbc0d;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            font-size: 10px;
          }
          .real-price {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .market-price {
              display: inline-block;
              margin-left: 5px;
              text-decoration: line-through;
            }
          }
        }
      }
    }
    .btn {
      min-width: 120px;
      button {
        font-weight: 600;
        width: 100%;
      }
    }
    .couponText {
      position: absolute;
      height: 34px;
      padding-bottom: 21px;
      bottom: 21px;
      background-color: rgba(255, 242, 203, 0.95);
      width: 100%;
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #222222;
      font-weight: 400;
      font-size: 12px;
      border-radius: 10px;
      .num {
        color: #db0007;
      }
    }
  }
}
