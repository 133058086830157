.tableware-container {
  padding: 0 20px;
  background-color: #fff;
  border-radius: 10px;
  .tableware-content,
  .remark-content {
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.1);
    .title {
      font-size: 14px;
      color: #222222;
      font-weight: bold;
    }
    .desc {
      margin-top: 5px;
      color: #999999;
      font-size: 12px;
      img.ep {
        width: 14px;
        height: 14px;
        object-fit: cover;
        margin-right: 5px;
      }
    }
    img.arrow {
      width: 16px;
      height: 16px;
    }
    .tableware-text {
      display: inline-flex;
      align-items: center;
    }
  }
  .invoice {
    .title {
      line-height: 20px;
      margin-top: 15px;
      font-size: 14px;
      color: #222222;
      font-weight: bold;
    }
    .invoice-content {
      padding: 5px 0 15px;
      color: #999999;
      font-size: 12px;
    }
  }
}

.tableware-popup-content,
.remark-popup-content {
  padding: 20px;
  .title {
    color: #222222;
    font-weight: 600;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  }
  .tableware-wrapper {
    .item {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 60px;
      display: flex;
      align-items: center;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      color: #222222;
      font-weight: 400;
      font-size: 14px;
      svg {
        path {
          fill: #000;
        }
      }
      img.ep {
        width: 14px;
        height: 14px;
        object-fit: cover;
        margin-right: 5px;
      }
    }
    .tableware-text {
      display: inline-flex;
      align-items: center;
    }
  }
  .remark-wrapper {
  }
  .btn-wrapper {
    width: 100%;
    height: 48px;
    margin: 15px 0 12px;
    button {
      width: 100%;
    }
  }
}
