.common-footer-container {
  height: 100vh;
  .tabs-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    img {
      height: 100%;
    }
    .title {
      color: #222;
      &.active {
        font-weight: bold;
      }
    }
  }
  .outlet-wrapper {
    height: calc(100vh - 50px - constant(safe-area-inset-bottom));
    height: calc(100vh - 50px - env(safe-area-inset-bottom));
  }
}
html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
