.verify-code-input {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.verify-code-line {
  height: 2px;
  margin-left: 10px;
  background-color: #f2f2f2;
}

.verify-code-line-error {
  height: 1px;
  margin-left: 10px;
  background-color: #d90007;
}

.verify-code-input-row {
  display: flex;
  margin-right: 20px;
  margin-left: 10px;
  flex-direction: row;
  justify-content: space-between;
}

.verify-phone-label {
  margin-left: 20px;
  margin-top: 2px;
  color: #999999;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
}

.verify-phone-input {
  flex: 1;
  height: 20px;
  margin-left: 10px;
}

.verify-code {
  flex-direction: row;
  display: flex;
  margin-top: 2px;
  height: 41px;
}

.verify-phone-text {
  margin-left: 20px;
  margin-top: 2px;
  color: #d90007;
  font-size: 10px;
  font-family: 'PingFangSC-Regular';
}
