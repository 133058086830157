@import '../../../styles/variable.less';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 16px 42px;
  align-items: center;
}

.img {
  width: 180px;
  min-height: 180px;
  height: auto;
}

.title {
  font-size: var(--adm-font-size-10);
}

.desc {
  font-size: var(--adm-font-size-8);
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: @font-weight-light;
  white-space: pre;
  text-align: center;
}

.button {
  margin-top: 20px;
  min-width: 185px;
}
