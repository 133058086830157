.detail-sv {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .detail-bg {
    display: flex;
    flex-direction: row;
    height: 67vw;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100% 87%;

    .detail-bg-content {
      margin-top: 7vw;
      margin-left: 20px;
      margin-bottom: 20vw;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      .detail-bg-top {
        display: flex;
        flex-direction: column;

        .detail-v-label {
          // overflow: hidden;
          // height: 42px;
          .detail-content-v {
            border-radius: 3px;
            border-width: 1px;
            border-style: solid;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            margin-bottom: 2px;
            .detail-content-label {
              padding: 2px 5px;
              font-family: PingFangSC-Medium;
              font-weight: bold;
              font-size: 10px;
            }
          }
          .tag-img {
            width: 100%;
            height: 20px;
            float: left;
          }
        }

        .detail-bg-title {
          line-height: 22px;
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          font-weight: bold;
          color: black;
          margin-top: 5px;
        }

        .detail-bg-subtitle {
          font-size: 12px;
          line-height: 12px;
          font-family: PingFangSC-Regular;
          color: black;
          margin-top: 5px;
        }
      }

      .detail-bg-bottom {
        display: flex;
        flex-direction: column;

        .detail-bg-times {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          .detail-bg-count {
            display: flex;
            flex-direction: column;
            flex: 1;
            .detail-title {
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: black;
              margin-right: 3px;
            }

            .detail-number {
              font-family: SpeedeeApp-Bold;
              font-size: 18px;
              color: black;
              margin-right: 10px;
            }
          }
        }

        .detail-bg-valid-time {
          font-size: 10px;
          font-family: PingFangSC-Light;
          color: #666666;
          width: 50vw;
          margin-top: 3px;
        }
      }
    }

    .detail-bg-img {
      margin-top: 7vw;
      width: 39vw;
      height: 39vw;
    }
  }

  .detail-content {
    position: absolute;
    top: 52vw;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .detail-title {
      width: calc(100vw - 20px);
      margin-top: 20px;
      margin-left: 20px;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      font-size: 15px;
      color: #222222;
    }

    .detail-content-description {
      width: calc(100vw - 40px);
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 10px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      font-size: 12px;
      color: #999999;
    }

    .detail-empty {
      height: 100px;
    }
  }
}

.product-coupon-divider {
  position: fixed;
  bottom: 90px;
  height: 1px;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.05);
}

.product-coupon-order {
  height: 90px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  position: fixed;
  background-color: white;
  bottom: 0;

  .product-coupon-code-view {
    border-width: 2px;
    border-color: rgba(34, 34, 34, 0.5);
    border-radius: 21px;
    border-style: solid;
    margin-left: 20px;
    margin-bottom: 35px;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;

    .product-coupon-code {
      width: 18px;
      height: 18px;
    }

    .product-coupon-code-text {
      color: #222222;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .product-coupon-phone-view {
    border-radius: 21px;
    background-color: #ffbc0d;
    flex-grow: 1;
    margin-left: 15px;
    margin-bottom: 33px;
    margin-right: 20px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    display: flex;

    .product-coupon-phone-text {
      color: #222222;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
    }
  }

  .coupon-send-to-friends {
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 250px;
    border: 1px solid #979797;
    margin-left: 15px;
    margin-bottom: 33px;
    margin-top: 15px;
    .product-coupon-phone-text {
      color: #222222;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
    }
  }
}
