.recharge-detail-pic-bg {
  position: absolute;
  top: 10px;
  left: 20px;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
}

.recharge-detail-pic {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.recharge-detail-pic-content-bg {
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.recharge-detail-pic-content-content {
  // display: flex;
  // align-items: baseline;
  margin-right: 22px;
}

.recharge-detail-unit {
  color: #ffffff;
  font-size: 16px;
  font-family: 'SpeedeeApp-Bold';
}

.recharge-detail-amount {
  color: #ffffff;
  font-size: 28px;
  line-height: 28px;
  font-family: 'SpeedeeApp-Bold';
}

.recharge-detail-cardId {
  margin-right: 20px;
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: 'DINAlternate-Bold';
  z-index: 2;
}

.recharge-detail-desc-bg {
  background-color: #ffffff;
  flex: 1;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.recharge-card-time-row-bg {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 21px;
}

.recharge-card-time-row-line {
  height: 40px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.recharge-card-time-bg {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.recharge-card-time-icon {
  height: 20px;
  width: 20px;
}

.recharge-card-time-desc-bg {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recharge-card-time-title {
  color: #222222;
  font-size: 14px;
  font-family: 'PingFangSC-Semibold';
}

.recharge-card-time-desc {
  color: #999999;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
}

.recharge-detail-title {
  margin-left: 20px;
  /* margin-top: 2PX; */
  margin-top: 25px;
  color: #222222;
  font-size: 18px;
  line-height: 18px;
  font-family: 'PingFangSC-Semibold';
}

.recharge-detail-desc {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 12px;
}

.recharge-detail-richtext {
  color: #999999;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  line-height: 24px;
}

.recharge-detail-bottom {
  background-color: #fff;
  padding: 30px 40px;
}

.recharge-detail-zs {
  background-color: #ffffff;
  flex: 2;
  margin-right: 15px;
  border: 2rpx solid #666666;
  height: 88px;
  border-radius: 20px;
  min-width: 88px;
  padding: 0 18px;
  font-size: 18px;
  font-weight: bold;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recharge-detail-place-holder {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  background-color: #fff;
}

.recharge-detail-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recharge-detail-confirm-text {
  margin-top: 30px;
  margin-bottom: 5px;
  font-family: 'PingFangSC-Semibold';
  color: #222222;
  font-size: 20px;
}

.recharge-detail-confirm-left-text {
  margin-top: 15px;
  width: 120px;
  color: #999999;
  font-size: 14px;
  line-height: 40px;
  font-family: 'PingFangSC-Regular';
}

.recharge-detail-confirm-right-text {
  margin-top: 15px;
  color: #222222;
  font-size: 16px;
  line-height: 40px;
  font-family: 'PingFangSC-Medium';
}

.recharge-detail-confirm-after-right-text {
  margin-top: 15px;
  color: #d90007;
  font-size: 16px;
  line-height: 40px;
  font-family: 'PingFangSC-Medium';
}

.recharge-detail-confirm-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recharge-detail-confirm-dashed {
  width: calc(100% - 28px);
  height: 1px;
  margin: 11px 15px 1px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.05);
}

.recharge-detail-confirm-tip-text {
  color: #222222;
  font-size: 10px;
  font-family: 'PingFangSC-Regular';
}

.recharge-detail-confirm-button-container {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.recharge-datail-confirm-tip-icon {
  width: 13px;
  height: 12px;
  flex-shrink: 0;
}

.recharge-detail-confirm-tip-container {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
