@import '../../styles/variable.less';

//price font-family
@font-face {
  font-family: SpeedeeApp-Bold;
  src: url('https://img.mcd.cn/mini/legacy/Speedee_A_Bd.ttf');
}

.product {
  &-container {
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    height: @100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &-banner {
    // position: absolute;
    // width: 100%;
    // top: 0;
    // left: 0;
    // right: 0;
    overflow: hidden;

    &.adm-swiper-horizontal .adm-swiper-indicator {
      bottom: 32px;
      right: 12px;
      left: auto;
    }
  }

  &-search-wrapper {
    position: fixed;
    width: 100%;
    top: calc(var(--height, 42) * -1px);
    left: 0;
    right: 0;
    z-index: 98;
    box-sizing: border-box;
    padding: 10px 20px 0;
    overflow: hidden;
    background-color: var(--adm-color-white);
    height: calc(var(--height, 42) * 1px);
    opacity: 0;

    &[data-full='true'] {
      opacity: 1;
      top: 0;
    }
  }

  &-coupon-discount {
    margin: 0 15px 15px;
  }

  &-menu-list-wrapper {
    position: relative;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    padding-top: 20px;
    background: var(--adm-color-white);
    // height: calc(100vh - 22px);
    // height: calc(@100vh - 22px);
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;
    margin-top: -1.428rem;

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #e5e5e5;
      width: 3rem;
      height: 8px;
      border-radius: 4px;
    }

    &[data-full='true'] {
      &:after {
        // display: none;
        background: transparent;
      }
    }

    .adm-side-bar-item-active {
      color: var(--adm-color-danger);
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      z-index: 1;

      .menu-list-sidebar-item-name {
        font-weight: @font-weight-bold;
      }
    }

    .menu-list-categoey-wrapper {
      .adm-selector-item-active {
        font-weight: 500;
      }
    }

    .adm-side-bar-items {
      // TODO safe gap
      padding-bottom: 120px;
    }
  }

  &-store-container {
    position: sticky;
    top: 42px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 97;
    background-color: var(--adm-color-white);
  }

  &-menu-list {
    // flex: 1;

    .adm-side-bar-item {
      padding: 16px 4px;
      justify-content: center;
    }

    .adm-side-bar-item-highlight {
      display: none;
    }
    .adm-selector-check-mark-wrapper {
      display: none;
    }

    .adm-side-bar-item {
      .adm-badge-wrapper {
        width: 100%;
      }
      .adm-side-bar-badge.adm-badge {
        --right: 8px;
        box-sizing: border-box;
        background-color: #db0007;
        width: 18px;
        height: 14px;
        text-align: center;
        color: #ffffff;
        font-family: PingFangSC-Semibold;
        border-radius: 7px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 7px;
      }
    }
  }
}
