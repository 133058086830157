@import '../../../../styles/variable.less';

.wrapper {
  height: @100vh;
  display: flex;
  flex-direction: column;
}

.scroll {
  flex: 1;
  overflow-y: auto;
  padding: 20px 15px;
}

@coupon-height: 56px;

.coupon {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: @coupon-height;
  background-color: #fff5e3;
  border-radius: 8px;
  padding-left: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.couponName {
  font-size: var(--adm-font-size-8);
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: hidden;
  white-space: nowrap;
  z-index: 1;
}

.couponSub {
  font-size: var(--adm-font-size-4);
  z-index: 1;
}

.couponImgLeft {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 65px;
  height: @coupon-height;
}

.couponImgRight {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 65px;
  height: @coupon-height;
}

.footer {
  padding: 15px 20px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &[data-supportsafearea='true'] {
    padding-bottom: var(--safe-area-inset-bottom);
  }
}

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
}

.productImg {
  width: 120px;
  flex-shrink: 0;
}

.productContent {
  flex: 1;
  min-width: 0;
  min-height: 90px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 20px 10px 5px;
}

.productName {
  font-weight: bold;
  font-size: var(--adm-font-size-6);
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.productCount {
  font-weight: @font-weight;
  vertical-align: middle;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  width: fit-content;
  border: 1px solid rgba(189, 189, 189, 0.5);
  font-size: 10px;
  color: #737373;
  margin-right: 5px;
  padding: 2px 5px;
}

.productCombo {
  width: max-content;
  max-width: 100%;
  font-size: var(--adm-font-size-2);
  color: #737373;
  background-color: #f6f6f6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  padding: 1px 5px;
  margin-top: 4px;
}

.productPrice {
  margin-top: 4px;
}

.productSelect {
  width: 20px;
  flex-shrink: 0;
}

.productSelectIcon {
  width: 20px;
  height: 20px;
}
