.canteen-detail-container {
  background-color: #fff;
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 44px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .detail-content {
    .base-info {
      padding: 10px 20px 25px;
      .name {
        line-height: 26px;
        font-size: 22px;
        color: #222;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .hot-tag-wrapper {
        .item {
          display: inline-flex;
          align-items: center;
          height: 16px;
          margin: 7px 5px;
          padding: 1px 6px;
          border-radius: 16px;
          border: 0.5px solid #d9d9d9;
          img {
            width: 12px;
            height: 12px;
            margin-right: 2px;
          }
          span {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #222;
          }
          &.tag_first_item_view {
            border: 0.5px solid rgba(219, 0, 7, 1);

            span {
              color: #d90007;
            }
          }
        }
      }
      .business-info {
        line-height: 16px;
        font-size: 12px;
        color: #999;
        .break {
          img {
            height: 11px;
            height: 11px;
            margin-right: 5px;
          }
        }
      }
    }
    .address-info {
      margin: 15px 20px;
      .address-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 66px;
        padding-left: 15px;
        background-image: url('https://img.mcd.cn/mini/main/images/detail_detail_icon_mapback.png');
        background-position: center;
        background-size: cover;
      }
    }
  }
}
