.select-canteen-container {
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 44px;
  .content {
    height: calc(~'100vh - 90px');
    width: 100vw;
    display: flex;
    flex-direction: column;
    .title-wrapper {
      box-sizing: border-box;
      width: 100%;
      height: 45px;
      padding: 13px 20px 12px;
      display: flex;
      align-items: center;

      .title {
        position: relative;
        color: #222222;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        &.selected {
          font-weight: 600;
        }
        .bg {
          position: absolute;
          bottom: -2px;
          width: 100%;
          height: 5px;
          border-radius: 27px 0px;
          background: linear-gradient(90deg, rgba(252, 187, 24, 1) 0%, rgba(252, 187, 24, 1) 100%);
        }
      }
      .filter-wrapper {
        position: absolute;
        right: 22px;
        display: flex;
        align-items: center;
        span {
          color: #0086d6;
          font-weight: 400;
          font-size: 14px;
          margin-right: 7px;
        }
        img {
          width: 12px;
          height: 8px;
          transform: rotate(0deg);
        }
        &.show {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }

    .canteen-container {
      flex-grow: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .err-content {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #222222;
    }
  }
}
