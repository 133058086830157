.product-list-conatiner {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;

  .title {
    color: #222222;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  }
  .product-content {
    padding: 20px 0;
    border-bottom: dashed 0.5px rgba(0, 0, 0, 0.15);
    .item {
      display: flex;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      img.product-img {
        flex-basis: 60px;
        margin-right: 10px;
        width: 60px;
        height: 45px;
        object-fit: cover;
      }
      .detail {
        flex-grow: 1;
        .name {
          color: #222222;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 5px;
        }
        .comboItems {
          color: #999999;
          font-weight: 400;
          font-size: 12px;
          .combo-item {
            line-height: 16px;
          }
        }
        .coupon-content {
          .coupon-item {
            display: flex;
            .symbol {
              font-size: 10px;
              color: white;
              font-family: PingFangSC-Regular;
              padding-left: 4px;
              padding-right: 3px;
              background-color: #db0007;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }
            .coupon-name-wrapper {
              display: inline-block;
              width: 90px;
              font-size: 10px;
              color: #db0007;
              font-family: PingFangSC-Regular;
              padding-left: 3px;
              padding-right: 3px;
              white-space: nowrap;
              overflow: hidden;
              white-space: nowrap;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              background-image: url(https://img.mcd.cn/mini/main/images/coupon_mid.png);
              background-size: 100% 100%;
              font-weight: normal;
            }
            .after-bg {
              width: 3.5px;
              height: 16px;
            }
          }
        }
        .price-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 0 5px;
          .price-content {
            .market {
              color: #cccccc;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              text-decoration: line-through;
              margin-right: 10px;
            }
            .real {
              color: #222222;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .delivery-price {
    display: flex;
    justify-content: space-between;
    color: #222222;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    .label {
      position: relative;
      align-items: center;
      width: calc(100% - 74px);
      & > span {
        flex-shrink: 0;
      }
      display: flex;
      .coupon-title {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #999999;
        font-size: 12px;
        line-height: 12px;
        .symbol {
          display: inline-block;
          line-height: 14px;
          text-align: center;
          width: 14px;
          height: 14px;
          margin-right: 5px;
          background-image: url(https://img.mcd.cn/mini/main/images/coupon_label.png);
          background-size: 100% 100%;
          color: #fff;
          font-size: 10px;
        }
      }
      .tips {
        position: absolute;
        right: 0;
        top: -3px;
        box-sizing: border-box;
        width: 101px;
        height: 42px;
        font-size: 11px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        padding-bottom: 16px;
        background-image: url(https://img.mcd.cn/mini/main/images/max_promotion.png);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .val {
      flex-basis: 74px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // color: #db0007;
      .market-price {
        text-decoration: line-through;
        font-family: SpeedeeApp-Regular;
        color: #c6c6c6;
        font-size: 12px;
        font-weight: 600;
      }
      .real-price {
        display: inline-flex;
        align-items: baseline;
      }
      .symbol {
        margin-left: 5px;
        font-size: 12px;
      }
      .price {
        font-size: 16px;
        font-family: SpeedeeApp-Bold;
        font-weight: 600;
      }
      img.arrow {
        width: 16px;
        height: 16px;
      }
    }
  }
  .order-coupon-info {
    display: flex;
    justify-content: space-between;
    color: #222222;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    .label {
      position: relative;
      align-items: center;
      width: calc(100% - 74px);
      & > span {
        flex-shrink: 0;
      }
      display: flex;
      .coupon-title {
        margin-left: 10px;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #999999;
        font-size: 12px;
        line-height: 12px;
        .symbol {
          display: inline-block;
          line-height: 14px;
          text-align: center;
          width: 14px;
          height: 14px;
          margin-right: 5px;
          background-image: url(https://img.mcd.cn/mini/main/images/coupon_label.png);
          background-size: 100% 100%;
          color: #fff;
          font-size: 10px;
        }
      }
      .tips {
        position: absolute;
        right: 0;
        top: -3px;
        box-sizing: border-box;
        width: 101px;
        height: 42px;
        font-size: 11px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        padding-bottom: 16px;
        background-image: url(https://img.mcd.cn/mini/main/images/max_promotion.png);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .val {
      flex-basis: 74px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #db0007;
      .symbol {
        font-size: 12px;
      }
      .price {
        font-size: 16px;
        font-family: SpeedeeApp-Bold;
        font-weight: 600;
      }
      img.arrow {
        width: 16px;
        height: 16px;
      }
    }
  }
  .product-coupon-info {
    display: flex;
    justify-content: space-between;
    color: #222222;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    .label {
      .desc {
        display: inline-block;
        margin-left: 10px;
        color: #999;
        line-height: 20px;
        font-size: 12px;
        font-weight: 200;
      }
    }

    .val {
      color: #db0007;
      .symbol {
        font-size: 12px;
      }
      .price {
        font-size: 16px;
        font-family: SpeedeeApp-Bold;
        font-weight: 600;
      }
    }
  }
  .total-price,
  .tableware-price {
    display: flex;
    justify-content: space-between;
    color: #222222;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
  }
  .division {
    position: relative;
    height: 14px;
    margin: 0 -20px;
    .line {
      height: 6px;
      margin: 0 auto;
      width: calc(~'100% - 40px');
      border-bottom: dashed 0.5px rgba(0, 0, 0, 0.15);
    }
    span {
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      background-color: #eee;
      border-radius: 50%;
      top: 0px;
    }
    .left {
      left: -7px;
    }
    .right {
      right: -7px;
    }
  }
  .real-total-price {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    color: #222222;
    font-weight: 400;
    font-size: 12px;
    padding: 10px 0;
    .discountAmount-wrapper {
      margin-right: 10px;
      .val {
        margin-left: 5px;
        color: #db0007;
        .price {
          font-weight: bold;
        }
      }
    }
    .realTotalPrice-wrapper {
      .symbol {
        margin: 0 2px;
      }
      .price {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  // ui问题统一处理
  .ui-symbol {
    font-size: 12px !important;
    font-weight: normal;
    font-family: initial !important;
    width: max-content;
  }
  .ui-val {
    font-size: 16px !important;
    font-family: 'SpeedeeApp-Bold';
    &.total {
      font-size: 20px !important;
    }
  }
}

.order-coupon-popup-content,
.freight-coupon-popup-content {
  padding: 20px;
  .title {
    color: #222222;
    font-weight: 600;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 25px;
    text-align: center;
  }
  .available-coupon {
    color: #999999;
    font-size: 13px;
    margin-bottom: 10px;
    .num {
      color: #db0007;
    }
  }
  .order-coupon-container,
  .freight-coupon-container {
    .item {
      box-sizing: border-box;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      // height: 60px;
      padding: 15px;
      display: flex;
      align-items: center;
      color: #222222;
      font-weight: 400;
      font-size: 14px;
      border-radius: 6px;
      border: 0.5px solid rgba(0, 0, 0, 0.16);
      margin-bottom: 10px;
      svg {
        path {
          fill: #000;
        }
      }
      .coupon-wrapper {
        width: 100%;
        display: flex;
        .pattern {
          flex-basis: 80;
          width: 80px;
          height: 64px;
          border-radius: 5px;
          background-image: url('https://img.mcd.cn/mini/main/images/coupon_confirm_bg.png');
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          color: #e21708;
          .detail {
            padding-top: 5px;
            height: 33px;
            .desc {
              display: inline-block;
              width: 12px;
              font-size: 12px;
              font-weight: bold;
              text-align: left;
              font-family: PingFangSC-Semibold;
              line-height: 12px;
              transform: scale(0.7);
            }
            .val-wrapper {
              display: inline-flex;
              align-items: baseline;
              .val {
                font-size: 26px;
                font-weight: 700;
                font-family: SpeedeeApp-Bold;
                margin: 0 1px;
              }
              .unit {
                font-size: 11px;
              }
            }
          }
        }
        .info {
          width: 172px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 3px;
            margin-bottom: 3px;
            color: #000000;
            font-size: 14px;
            font-weight: bold;
            font-family: PingFangSC-Semibold;
            text-align: left;
          }
          .count {
            color: #222222;
            font-size: 11px;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
  .btn-wrapper {
    width: 100%;
    height: 48px;
    margin: 15px 0 12px;
    button {
      width: 100%;
    }
  }
}
