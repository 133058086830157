@import '../../../../styles/variable.less';

.product-com-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2f2;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: @text-color;
  border-radius: 16px;

  &-icon {
    margin-right: 6px;
    font-size: 20px;
    color: @text-tertiary;
  }

  .adm-input-clear {
    padding-right: 8px;
    color: @text-tertiary;

    &:active {
      color: @text-secondary;
    }
  }
}
