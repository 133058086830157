.status-outside {
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .status-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .status-title-normal {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #222222;
    }

    .status-title-color {
      font-family: DINAlternate-Bold;
      font-weight: bold;
      font-size: 18px;
      color: #db0007;
    }
  }

  .status-view {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;

    .status-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .status-img {
        width: 30px;
        height: 30px;
      }

      .status-text {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
