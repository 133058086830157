.cardlist-page {
  width: 100%;
  .content-view {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    display: flex;
    flex-direction: column;
    .adm-tabs-header {
      border-bottom: solid 0px var(--adm-color-border);
    }
    .adm-tabs-tab {
      padding: 8px 0 0px;
    }
    .adm-space-horizontal.adm-space-wrap {
      flex-wrap: nowrap;
      overflow-x: scroll;
      width: 100%;
    }
    .coupon-filter {
      //   width: 100vw;
      box-sizing: border-box;
      padding: 10px 0;
      flex-direction: row;
      white-space: nowrap;
      height: 50px;
      position: sticky;
      z-index: 100;

      .adm-space {
        .adm-space-item {
          &:first-child {
            margin-left: 20px;
          }
          &:last-child {
            margin-right: 20px;
          }
        }
      }

      // antd-mobile
      .adm-selector-item-active {
        box-sizing: border-box;
        font-weight: 700;
        border-width: 2px;
        padding: 5px 9px;
      }
    }
    .product-coupon-view {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 50px);
      overflow-y: auto;
    }
  }
}
