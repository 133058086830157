.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;

  &[data-visible='true'] {
    display: block;
  }
}

.popupMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.popupBody {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  transition: transform 0.2s;
  transform: translateY(100%);
  background-color: #ffffff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  &[data-visible='true'] {
    transform: translateY(0);
  }
}
