.mealtime-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  .mealtime-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      color: #222222;
      font-weight: 600;
      font-size: 16px;
    }
    .time {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        color: #f6ab01;
        font-weight: 400;
        font-size: 14px;
        margin-right: 5px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.time-popup-content {
  padding: 20px;
  .title {
    color: #222222;
    font-weight: 600;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  }
  .time-wrapper {
    .item {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      color: #222222;
      font-weight: 400;
      font-size: 14px;
      svg {
        path {
          fill: #000;
        }
      }
    }
  }
  .btn-wrapper {
    width: 100%;
    height: 48px;
    margin: 15px 0 12px;
    button {
      width: 100%;
    }
  }
}
