@import '../variable.less';

.adm-button {
  font-size: var(--adm-font-size-10);
  line-height: 1;
  padding: 11px 12px;

  &&-large {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: var(--adm-font-size-10);
  }
  &&-small {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: var(--adm-font-size-8);
    line-height: 1.375;

    &[data-less] {
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: var(--adm-font-size-4);
      font-weight: @font-weight;
      line-height: 1;
    }
  }
  &&-mini {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: var(--adm-font-size-2);
    font-weight: 500;
  }

  &[data-blod] {
    font-weight: @font-weight-bold;
  }

  &&-primary {
    --text-color: @text-color !important;
  }

  &-danger,
  &-primary {
    &.adm-button-disabled {
      opacity: 1;
      background: @disabled-color;
      border-color: @disabled-color;
      color: @text-tertiary;
    }
  }

  &-default {
    &.adm-button-disabled {
      opacity: 1;
      border-color: @text-tertiary;
      color: @text-tertiary;
    }
  }
}
