.protocol-view {
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow-y: auto;

  .item-view {
    display: flex;
    flex-direction: column;

    .item-divider {
      width: calc(100% - 40px);
      height: 1px;
      margin-left: 20px;
      margin-right: 20px;
      background-color: rgba(0, 0, 0, 0.05);
    }

    .item-content {
      width: calc(100% - 40px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
      padding: 18px 0;

      .item-title {
        font-family: PingFangSC-Semibold;
        font-weight: bold;
        font-size: 16px;
        color: #222222;
      }

      .item-arrow {
        width: 16px;
        height: 16px;
      }
    }
  }
}
