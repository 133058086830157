.user-info-container {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .home_user_line_view {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    .home_user_customer_left_view {
      display: flex;
      flex-direction: row;
      .customer_card_image {
        width: 58px;
        height: 50px;
        flex-shrink: 0;
      }
      .customer_user_info_view {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        height: 56px;
        justify-content: center;
        .customer_main_title {
          font-family: 'PingFangSC-Medium';
          font-weight: bold;
          font-size: 14px;
          color: #ffd099;
          // white-space: nowrap;
          // overflow: hidden;
          height: 20px;
          // text-overflow: ellipsis;
          max-width: 180px;
        }
        .customer_subline_view {
          display: flex;
          flex-direction: row;
          align-items: center;
          .customer_subTitle {
            font-family: 'PingFangSC-Light';
            font-weight: 300;
            font-size: 10px;
            color: #ffd099;
          }
          .arrow {
            margin-left: 3px;
          }
          .customer_right_arrow {
            margin-left: 3px;
            width: 10px;
            height: 10px;
            flex-shrink: 0;
          }
        }
      }
    }
    .home_user_login_button_view {
      margin-top: 16px;
      margin-right: 10px;
      background-color: #ffbc0d;
      border-radius: 12px;
      width: 60px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      .home_user_login_button_text {
        color: #222222;
        font-size: 12px;
        font-family: 'PingFangSC-Semibold';
        font-weight: bold;
      }
    }
    .home_user_card_left_view {
      margin-left: 18px;
      display: flex;
      flex-direction: row;
    }
    .home_user_right_point_view {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 12px;
      justify-content: center;
      height: 56px;
      .home_user_right_point_title_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        .home_user_right_point_title {
          color: #ffd099;
          font-size: 10px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
        }
        .home_user_right_point_icon {
          margin-left: 3px;
          width: 10px;
          height: 10px;
        }
      }
      .home_user_right_point_num {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .home_user_right_intPoint_num {
          margin-left: 2px;
          font-size: 18px;
          height: 24px;
          line-height: 24px;
          font-family: 'Futura-Medium';
          font-weight: bold;
          color: #ffd099;
        }
        .home_user_right_smallPoint_num {
          font-size: 12px;
          height: 15px;
          line-height: 15px;
          // margin-top: 13px;
          font-family: 'Futura-Medium';
          font-weight: bold;
          color: #ffd099;
        }
      }
    }
  }
}
