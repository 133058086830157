.coupon-container {
  display: flex;
  height: 16px;
  line-height: 16px;
  .symbol {
    font-size: 10px;
    color: white;
    font-family: PingFangSC-Regular;
    padding-left: 4px;
    padding-right: 3px;
    background-color: #db0007;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .coupon-name-wrapper {
    display: inline-block;
    // width: 90px;
    font-size: 10px;
    color: #db0007;
    font-family: PingFangSC-Regular;
    padding-left: 3px;
    padding-right: 3px;
    white-space: nowrap;
    background-image: url(https://img.mcd.cn/mini/main/images/coupon_mid.png);
    background-size: 100% 100%;
    font-weight: normal;
  }
  .after-bg {
    width: 3.5px;
    height: 16px;
  }
}
