.place-list {
  padding-top: 5px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    box-sizing: border-box;
    height: 73px;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.selected {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 248, 228, 1) 100%);
    }
    .name {
      color: #222222;
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
    }
    .address {
      color: #666666;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
    }
  }
}
