.search-canteen-container {
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 44px;
  .content {
    height: calc(~'100vh - 90px');
    width: 100vw;
    display: flex;
    flex-direction: column;

    .tags-wrapper {
      margin-top: 15px;
    }
    .canteen-container {
      flex-grow: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .err-content {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #222222;
    }
  }
}
