.select-city {
  // padding-top: 44px;
  box-sizing: border-box;
  height: 100vh;
  .city-container {
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .city-list {
      padding-top: 12px;
      .city-item {
        height: 40px;
        line-height: 40px;
        padding-left: 25px;
      }
    }
    // antd-m
    .adm-index-bar-anchor-title {
      background-color: #fff;
    }
  }
}
