@import '../../../../../../styles/variable.less';

.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  font-size: var(--adm-font-size-2);
  font-weight: 500;
  border-radius: 11px;
  background: @primary-color;
  background-size: 100% 100%;
}

.selectCount {
  position: absolute;
  top: -8px;
  right: -5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: @accent-color;
  font-size: var(--adm-font-size-2);
  text-align: center;
  color: var(--adm-color-white);
  line-height: 16px;
}

.stepperWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepperButtonItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepperImg {
  width: 22px;
  height: 22px;
}

.stepperCount {
  text-align: center;
  font-size: var(--adm-font-size-7);
  flex-shrink: 0;
  width: 22px;
}
