.home_activity_bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  .home_activity_floor_one_view_selected {
    animation: rotate-scale-first 200ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  @keyframes rotate-scale-first {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.95, 0.95);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  .home_activity_floor_view {
    width: calc(100vw - 30px);
    height: calc((100vw - 30px) * 325 / 345);
    margin-top: 15px;
    position: relative;
    .home_activity_floor_one_view {
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
    .home_activtiy_floor_top_bottom_item {
      flex-shrink: 0;
      width: 100%;
      height: 50%;
    }
    .home_activtiy_floor_left_right_item {
      flex-shrink: 0;
      width: 50%;
      height: 100%;
    }
    .home_activtiy_floor_leftTB_left_item {
      flex-shrink: 0;
      width: 50%;
      height: 100%;
    }
    .home_activtiy_floor_leftTB_right_item {
      flex-shrink: 0;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .home_activtiy_floor_leftTB_top_item {
        flex-shrink: 0;
        width: 100%;
        height: 50%;
      }
    }
    .home_activtiy_floor_topLR_top_item {
      flex-shrink: 0;
      height: 50%;
      width: 100%;
    }
    .home_activtiy_floor_topLR_left_item {
      flex-shrink: 0;
      height: 50%;
      width: 50%;
    }
    &:first-child {
      margin-top: 0px;
    }
  }
}
