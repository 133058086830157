.order-confirm-conatiner {
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 44px;
  background-color: #eee;
  .order-confirm-content {
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    padding: 15px;
    padding-bottom: 120px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
