@import '../../../../../styles/variable.less';

@item-width: 90px;

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-row-gap: 15px;
  width: 100%;
  background-color: var(--adm-color-white);
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}

.itemSelectIcon,
.itemSoldOutIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 1px;
  right: -8px;
  z-index: 2;
}

.itemView {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: @item-width;
  height: @item-width;
  margin-top: 18px;
  border-radius: 50%;
  border: 2px solid rgba(204, 204, 204, 0.5);
  align-items: center;
  justify-content: center;

  &[data-selected='true'] {
    border-color: rgba(255, 188, 13, 1);
  }
}

.itemViewImg {
  width: 74px;
  height: auto;
  border-radius: 30%;
}

.itemViewSoldOutMask {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  opacity: 0.4;
  background-color: #eaeaea;
  width: @item-width;
  height: @item-width;
  border-radius: 50%;
  overflow: hidden;
}

.itemName {
  margin-top: 10px;
  color: @text-secondary;
  font-size: var(--adm-font-size-2);
  text-align: center;

  &[data-selected='true'] {
    color: @text-color;
    font-weight: @font-weight-bold;
  }
}
