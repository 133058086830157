.detail-index {
  flex-direction: column;
  width: 100vw;
  background-color: #eeeeee;
  height: 100vh;

  .more-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 105;
  }

  .header_title {
    position: relative;
  }

  .detail_index_title {
    position: absolute;
    line-height: 22px;
    color: rgba(34, 34, 34, 100);
    font-size: 18px;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-weight: bold;
  }

  .detail_top_tips_view {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    left: 15px;
    top: 121vw;
    border-radius: 21px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ffffff;
    .detail_top_tips_left_icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
    .detail_top_tips_right_icon {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
    .detail_top_tips_txt {
      flex: 1;
      color: #68c800;
      font-size: 12px;
    }
  }

  .refresh {
    position: absolute;
    width: 42px;
    height: 42px;
    right: 4%;
    top: 120vw;
  }

  .bottom-bar {
    display: flex;
    position: fixed;
    bottom: 0;
    height: 86px;
    width: 100%;
    z-index: 100;
    background-color: white;

    .bottom-bar-list {
      top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      flex: 1;
      position: absolute;
      width: calc(100% - 40px);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      // 新增
      .bottom-bar-list-left {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 24px;
        height: 24px;
        margin-top: 10px;

        .bottom-bar-list-left-title {
          line-height: 24px;
        }
      }

      .bottom-bar-buttons {
        display: flex;
        flex-direction: row-reverse;
        height: 30px;
        .bottom-item {
          display: flex;
          justify-content: center;
          align-items: center;
          border-style: solid;
          border-width: 0.5px;
          border-radius: 18px;

          .bottom-item-title {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            padding: 6px 10px;
            color: #222222;
          }
        }

        .bottom-item-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          border-style: solid;
          border-width: 0.5px;
          border-radius: 18px;
          padding: 0;
          margin: 0;
          height: 30px;

          .bottom-item-title {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            line-height: 12px;
            padding: 6px 10px;
            color: #222222;
          }
        }

        .bottom-item-btn::after {
          border: none;
        }
      }

      .bottom-bar-more-view {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .bottom-bar-more {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .pop_view {
    position: absolute;
    width: 100px;
    display: flex;
    background-size: 100%;
    left: 20px;
    bottom: 86px;
    flex-direction: column;
    z-index: 110;

    .pop_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: rgba(43, 43, 43, 0.95);
      border-radius: 5px;

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;

        .pop_line {
          width: 100%;
          height: 1px;
          border-top: 0.5px solid rgba(204, 204, 204, 0.1);
        }

        .text {
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          line-height: 48px;
          text-align: center;
          font-family: PingFangSC-Regular;
        }
      }
    }

    .top_arrow {
      height: 5px;
      width: 10px;
      margin-left: 10px; //6 + 上面的left
      transform: rotate(180deg);
    }
  }

  .header-bg {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 520px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    background-position-y: -70px;

    .header-bg-title-view {
      display: flex;
      flex-direction: row;
      align-items: center;

      // 新增
      .header-bg-title_tag {
        width: 55px;
        height: 14px;
        margin-left: 4px;
        margin-top: calc(25vw + 5px);
        border-top-left-radius: 6px;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;

        .header-bg-title_tag_name {
          color: rgba(34, 34, 34, 100);
          font-size: 9px;
          font-family: PingFangSC-Regular;
        }
      }

      .header-bg-title-image {
        background-color: #db0007;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        line-height: 13px;
        margin-left: 10px;
        margin-top: 8vw;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;

        .header-bg-image {
          color: white;
          font-size: 9px;
          font-weight: bold;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      .header-channel-bg-title-image {
        background-color: #ffbc0d;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        line-height: 13px;
        margin-left: 10px;
        margin-top: 8vw;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;

        .header-channel-bg-image {
          color: #222222;
          font-size: 9px;
          font-weight: bold;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      .header-bg-title {
        font-weight: bold;
        color: #222222;
        margin-left: 20px;
        margin-top: 8vw;
        letter-spacing: 0px;
      }
    }

    .header-bg-sub-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      margin-top: 2px;

      .header-bg-sub-image {
        width: 12px;
        height: 11px;
      }

      .header-bg-subtitle {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #222222;
        margin-left: 3px;
      }
    }
  }

  .map {
    width: 100vw;
    position: fixed;
  }

  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dash-line {
    border-top: 1px dashed rgba(0, 0, 0, 0.05);
  }

  .semi-circle-left {
    border-radius: 0 7px 7px 0;
    height: 12px;
    width: 7px;
    background-color: #eeeeee;
  }

  .semi-circle-right {
    border-radius: 7px 0 0 7px;
    height: 12px;
    width: 7px;
    background-color: #eeeeee;
  }

  .scrollview {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .scrollview-empty-header {
      height: 30vw;
      z-index: 10;
    }

    .scrollview-empty-mds-header {
      height: 134vw;
      z-index: 10;
    }

    .scrollview-list {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      background-color: white;
      margin: 0 15px;
      width: calc(~'100vw - 30px');
      z-index: 10;

      .standing_positon {
        height: 60px;
        width: 100%;
      }
    }

    .cabinet-outside {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #eeeeee;

      .cabinet-hint {
        height: 35px;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #fff8e4;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .cabinet-hint-icon {
          width: 12px;
          height: 12px;
        }

        .cabinet-hint-text {
          margin-left: 3px;
          color: #824f09;
          font-size: 12px;
        }
      }

      .cabinet-inside {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .cabinet-code {
          display: flex;
          flex-direction: row;
          justify-content: center;
          position: relative;
          width: 100%;

          .cabinet-code-text {
            font-family: SpeedeeApp-Bold;
            font-size: 30px;
            color: #222222;
            margin-top: 20px;
          }

          .cabinet-code-icon {
            position: absolute;
            margin-top: 20px;
            right: 21%;
            background-color: #db0007;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            line-height: 13px;
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;

            .cabinet-code-icon-text {
              color: white;
              font-family: PingFangSC-Semibold;
              font-weight: bold;
              font-size: 9px;
              color: #ffffff;
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 2px;
              padding-bottom: 1px;
            }
          }
        }

        .cabinet-qrcode {
          margin-top: 15px;
          margin-left: 20px;
          margin-bottom: 20px;
          width: 225px;
          height: 210px;
        }
      }

      .cabinet-divider {
        width: 100%;
        height: 10px;
        background-color: #eeeeee;
      }
    }

    .wechatcode-outside {
      display: flex;
      flex-direction: column;
      background-color: transparent;

      .wechatcode-index {
        display: flex;
        flex-direction: row;
        padding: 20px;
        justify-content: space-between;
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .wechatcode-content {
          display: flex;
          flex-direction: column;

          .wechatcode-title {
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
            color: #222222;
          }

          .wechatcode-subtitle {
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 12px;
            color: #666666;
            margin-top: 10px;
            line-height: 19px;
          }
        }

        .wechatcode-qr {
          width: 112px;
          height: 112px;
          flex-shrink: 0;
        }
      }

      .wechatcode-divider {
        width: 100%;
        height: 10px;
      }
    }

    .status-outside {
      display: flex;
      flex-direction: column;

      .status-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        .status-title-normal {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #222222;
        }

        .status-title-color {
          font-family: DINAlternate-Bold;
          font-weight: bold;
          font-size: 18px;
          color: #db0007;
        }
      }

      .status-view {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100px;

        .status-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          .status-img {
            width: 30px;
            height: 30px;
          }

          .status-text {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
    }

    .shop-header {
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .shop-header-content {
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .shop-header-img {
          width: 20px;
          height: 20px;
        }

        .shop-header-text {
          margin-left: 5px;
          font-family: PingFangSC-Semibold;
          font-weight: bold;
          font-size: 14px;
          color: #222222;
        }
      }
    }

    .product-index {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100vw - 70px);

      .product-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;

        // 新增
        .product-item-super {
          width: 48px;
          height: 48px;
          border-radius: 5px;
          background-color: rgba(246, 246, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .product-item-image-super {
            width: 100%;
            height: 100%;
          }
        }

        .product-item-image {
          width: 60px;
          height: 45px;
        }

        .product-item-content {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          flex: 1;

          .product-item-title {
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
            color: #222222;
          }

          .product-item-title-coupon {
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
            color: #222222;

            &::before {
              content: '';
              vertical-align: middle;
              background-image: url(https://img.mcd.cn/mini/main/images/order_icon_coupon.png);
              background-size: 14px 14px;
              background-position-y: 1px;
              background-repeat: no-repeat;
              width: 18px;
              height: 18px;
              display: inline-block;
            }
          }

          .product-item-combo {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #999999;
            margin-top: 7px;
            line-height: 12px;
          }

          .product-item-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 12px;

            .product-item-quantity {
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #222222;
            }

            .product-item-price-view {
              display: flex;
              flex-direction: row;
              align-items: center;

              .product-item-origin-price-view {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                margin-right: 10px;

                .product-item-price-unit {
                  font-family: SpeedeeApp-Regular;
                  text-decoration: line-through;
                  font-size: 12px;
                  color: #cccccc;
                }

                .product-item-price {
                  font-family: SpeedeeApp-Regular;
                  text-decoration: line-through;
                  font-size: 14px;
                  color: #cccccc;
                }
              }

              .product-item-real-price-view {
                display: flex;
                flex-direction: row;
                align-items: baseline;

                .product-item-price-unit {
                  // font-family: SpeedeeApp-Regular;
                  font-size: 12px;
                  color: #222222;
                }

                .product-item-price {
                  font-family: SpeedeeApp-Bold;
                  font-size: 16px;
                  color: #222222;
                }
              }

              .order_detail_price-tip-view {
                display: flex;
                flex-direction: row;
                margin-left: 5px;
                .order_detail_price-tip-image-left {
                  width: 7px;
                  height: 16px;
                  flex-shrink: 0;
                }

                .order_detail_price-tip-image-middle {
                  width: max-content;
                  height: 16px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  flex-shrink: 0;

                  .order_detail_price-tip-text {
                    font-size: 10px;
                    color: #ffa30d;
                  }
                }

                .order_detail_price-tip-image-right {
                  width: 4px;
                  height: 16px;
                  flex-shrink: 0;
                }
              }
            }
          }
        }
      }
    }

    .coupon-index {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .coupon-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;

        .coupon-item-text-view {
          display: flex;
          flex-direction: row;
          align-items: center;

          .coupon-item-packing-icon {
            width: 12px;
            height: 12px;
            margin-left: 5px;
          }

          .coupon-item-card-type {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: fit-content;
            background-color: #383836;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            margin-left: 10px;

            .coupon-item-card-type-text {
              font-family: PingFangSC-Regular;
              font-size: 9px;
              color: #ffdd8f;
              padding: 2px 3px;
            }
          }

          .coupon-item-card {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: fit-content;
            background-color: #ffdd8f;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;

            .coupon-item-card-text {
              font-family: PingFangSC-Regular;
              font-size: 9px;
              color: #383836;
              padding: 2px 3px;
            }
          }

          .coupon-item-text {
            color: #222222;
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
          }

          .coupon-item-text-coupon {
            color: #222222;
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
            &::before {
              content: '';
              vertical-align: middle;
              background-image: url(https://img.mcd.cn/mini/main/images/order_icon_coupon_red.png);
              background-size: 14px 14px;
              background-repeat: no-repeat;
              width: 18px;
              height: 18px;
              display: inline-block;
            }
          }

          .promotion-item-text-coupon {
            color: #222222;
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
          }
          .promotion-item-text-coupon::before {
            content: '';
            vertical-align: middle;
            background-image: url(https://img.mcd.cn/mini/main/images/order_icon_promotion_red.png);
            background-size: 14px 14px;
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            display: inline-block;
          }
          .coupon-item-text-coupon-alipay {
            color: #222222;
            font-family: PingFangSC-Semibold;
            font-weight: bold;
            font-size: 14px;
          }
          .coupon-item-text-coupon-alipay::before {
            content: '';
            vertical-align: middle;
            background-image: url(https://img.mcd.cn/mini/main/images/order_icon_coupon_red.png);
            background-size: 14px 14px;
            background-repeat: no-repeat;
            width: 14px;
            height: 14px;
            display: inline-block;
          }

          .coupon-item-text-sub {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #999999;
            margin-left: 10px;
          }
        }

        .coupon-item-price-view {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          .coupon-item-price-unit {
            font-family: SpeedeeApp-Regular;
            font-size: 12px;
          }

          .coupon-item-price {
            font-family: SpeedeeApp-Bold;
            font-size: 16px;
          }
        }
      }
    }

    .bill-index {
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      .bill-view {
        display: flex;
        flex-direction: row;
        width: calc(100% - 20px);
        height: 60px;
        margin-right: 20px;
        justify-content: flex-end;
        align-items: flex-end;

        .bill-rebate-coupon {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-bottom: 15px;
          height: 24px;
          font-size: 12px;
          flex: 1;
          margin-left: 20px;
          .rebate-coupon {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #fafafa;
            padding: 4px 0px 4px 5px;
            border-radius: 5px;
            border-color: #ebebeb;
            border-width: 1px;
            border-style: solid;
            color: #333333;
            .icon-rebate {
              width: 14px;
              height: 14px;
              margin-right: 5px;
            }
            .icon-right-arrow {
              width: 16px;
              height: 16px;
            }
            .rebate-red-title {
              color: #db0007;
            }
          }
        }
        .bill-discount-view {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          margin-bottom: 20px;

          .bill-discount-title {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #222222;
          }

          .bill-discount-unit {
            // font-family: SpeedeeApp-Regular;
            font-size: 12px;
            color: #db0007;
            margin-left: 5px;
          }

          .bill-discount {
            font-family: SpeedeeApp-Bold;
            font-size: 16px;
            color: #db0007;
          }
        }

        // 新增
        .bill_descript_view {
          margin-bottom: 10px;

          .bill_descript {
            width: 10px;
            height: 10px;
          }

          .bill_descript_text {
            color: rgba(51, 51, 51, 100);
            font-size: 12px;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }

    .title-view {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;

      .title-title-view {
        display: flex;
        flex-direction: column;
        height: 60px;
        justify-content: center;

        .title-title {
          font-family: PingFangSC-Semibold;
          font-weight: bold;
          font-size: 16px;
          color: #222222;
        }
      }

      .title-divider {
        height: 0.5px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .info-index {
      display: flex;
      flex-direction: column;

      .info-view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 17px;
        margin-bottom: 17px;
        margin-left: 20px;
        margin-right: 20px;

        .info-title {
          font-family: PingFangSC-Light;
          font-size: 14px;
          color: #999999;
          flex-shrink: 0;
        }

        .info-info-view {
          display: flex;
          flex-direction: row;
          align-items: center;

          .info-info-list {
            display: flex;
            flex-direction: column;

            .info-info {
              flex: 1;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #222222;
              text-align: end;
              width: 65vw;
              word-break: break-word;
            }

            .info-info-desc {
              flex: 1;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #222222;
              text-align: end;
              word-break: break-word;
            }
          }
        }
      }

      .info-info-refund-tip-view {
        margin-left: calc(100% - 177px - 36px); // 按照一行13个字 + 5的间距 + 14的图标宽度
        margin-right: 36px;
        margin-bottom: 20px;
        margin-top: -15px;
        display: flex;
        flex-direction: row;

        .info-info-refund-icon {
          width: 14px;
          height: 14px;
          margin-top: 1px;
          flex-shrink: 0;
        }

        .info-info-refund-subDesc {
          margin-left: 3px;
          line-height: 16px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #999;
          text-align: end;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
        }
      }

      .info-arrow {
        width: 6px;
        height: 10px;
        margin-left: 10px;
      }

      .info-payment {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }

      .info-copy {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }

    .maimai_invoice_view {
      display: flex;
      flex-direction: column;
      min-height: 60px;
      margin-left: 20px;
      margin-right: 20px;

      .invoice-title {
        font-family: PingFangSC-Semibold;
        font-weight: bold;
        font-size: 16px;
        color: #222222;
        flex-shrink: 0;
        margin: 20px 0 5px 0px;
      }

      .invoice-info-view {
        width: calc(100vw -70px);
        display: flex;
        flex-wrap: wrap;
        // flex-direction: row;
        // align-items: center;

        .invoice-info {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #999999;
          text-align: left;
          margin-bottom: 20px;
        }

        // .invoice-arrow {
        //   width: 6Px;
        //   height: 10Px;
        //   margin-left: 5Px;
        // }
      }
    }

    .invoice-view {
      display: flex;
      flex-direction: row;
      // min-height: 60px;
      // margin-left: 20px;
      // margin-right: 20px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;

      .invoice-title {
        font-family: PingFangSC-Semibold;
        font-weight: bold;
        font-size: 16px;
        color: #222222;
        flex-shrink: 0;
        margin-right: 10px;
        align-self: flex-start;
      }

      .invoice-info-view {
        display: flex;
        flex-direction: row;
        align-items: center;

        .invoice-info {
          display: flex;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #999999;
          text-align: right;
        }

        .invoice-arrow {
          width: 6px;
          height: 10px;
          margin-left: 5px;
        }
      }
    }

    .dt-banner {
      background-color: #fff8e4;
      display: flex;
      flex-direction: row;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      align-items: center;
      padding: 8px 10px 8px 15px;
      min-height: 28px;
      .dt_tip_area {
        display: flex;
        flex-grow: 1;
        .dt-icon {
          width: 10px;
          height: 10px;
          margin-top: 4px;
        }
        .dt-tips {
          margin-left: 3px;
          flex: 1;
          font-weight: 300;
          color: #824f09;
          font-size: 12px;
        }
      }
      .dt-notice-btn {
        height: 28px;
        padding: 0 10px;
        line-height: 28px;
        font-size: 12px;
        font-weight: 700;
        background-color: #ffbc0d;
        border-radius: 18px;
        flex-shrink: 0;
        margin-left: 5px;
      }
    }
  }

  .banner-container {
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 10px;
    position: relative;
    width: 92%;
  }

  .banner-key-container {
    margin: -6px 2.4% 6px;
    position: relative;
    width: 95.2%;
  }

  .banner-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    z-index: 10;
  }

  .key-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    z-index: 10;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .key-top-container {
    display: flex;
    margin: 30rpx 60rpx 0rpx 60rpx;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .key-title {
    font-size: 32rpx;
    color: #ffffff;
    font-weight: bold;
  }

  .key-copy-container {
    position: relative;
    width: 166.8rpx;
    height: 70rpx;
    display: flex;
    justify-content: center;
  }

  .key-copy-bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .key-subtitle {
    font-size: 24rpx;
    text-align: center;
    font-weight: bold;
    z-index: 1;
    color: #e37900;
    line-height: 67rpx;
  }

  .key-content {
    font-size: 24rpx;
    color: #aa6d4e;
    margin-bottom: 23rpx;
  }
}

.cancel-wrapper .adm-dialog-action-row button {
  &:last-child {
    background-color: #ffbc0d;
  }
}
