@import '../variable.less';

:root:root {
  // --adm-font-size-1: 9px;
  // --adm-font-size-2: 10px;
  // --adm-font-size-3: 11px;
  // --adm-font-size-4: 12px;
  // --adm-font-size-5: 13px;
  // --adm-font-size-6: 14px;
  // --adm-font-size-7: 15px;
  // --adm-font-size-8: 16px;
  // --adm-font-size-9: 17px;
  // --adm-font-size-10: 18px;

  --adm-color-primary: @primary-color;
  --adm-color-success: #00b578;
  --adm-color-warning: @primary-color;
  --adm-color-danger: @error-color;

  --adm-color-white: #ffffff;
  --adm-color-text: @text-color;
  --adm-color-text-secondary: @text-secondary;
  --adm-color-weak: @text-tertiary;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #ffffff;

  --adm-font-size-main: var(--adm-font-size-5);

  --adm-font-family: -apple-system, PingFangSC;

  // button
  --adm-button-border-color: @text-secondary;
  --adm-button-text-color: @text-color;

  // popup
  --adm-center-popup-border-radius: 10px;
}

@import './button.less';
@import './modal.less';
