.options-list {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  .options-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 0px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    .item-left {
      flex: 1;
      .item-title {
        font-size: 16px;
        color: #222222;
        font-weight: 700;
      }
      .item-tips {
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        margin-top: 5px;
      }
    }
    .item-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      .item-title {
        margin-right: 4px;
        font-size: 16px;
        color: #0086d6;
      }
      .icon {
        display: block;
        width: 16px;
        height: 16px;
        overflow: hidden;
      }
    }
  }
}

.options-btm {
  width: 100vw;
  position: fixed;
  bottom: 0px;
  background: #fff;
  height: 91px;
  border-top: solid 0.5px rgba(0, 0, 0, 0.1);
  .btm-quit {
    background-color: #ffbc0d;
    border-radius: 24px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    margin: 15px 20px 0 20px;
    font-size: 18px;
  }
}
