.common-coupon-index-outside {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  flex-shrink: 0;
  position: relative;

  .common-coupon-index {
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(~'100vw - 40px');
    .bg-coupon-pickup-yellow {
      flex-shrink: 0;
      width: 8px;
      flex-basis: 8px;
      background: #fcbb18;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .bg-coupon-delivery-red {
      width: 8px;
      flex-basis: 8px;
      background: #d90007;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .common-coupon-content {
      position: relative;
      // height: 158Px;
      // display: flex;
      flex: 1;
      // flex-direction: row;
      // align-items: center;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.05);
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-left: white;
      .common-coupon-content-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        .coupon-group {
          position: relative;
          height: 100px;
          .common-coupon-content-img {
            width: 100px;
            height: 100px;
          }
          .coupon-group-more {
            position: absolute;
            bottom: 5px;
            width: 66px;
            height: 22px;
            //background-color: rgb(228 134 34 / 0.5);
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: auto;
            left: 0;
            right: 0;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 10;
            .more-title {
              color: #ffffff;
              font-size: 12px;
            }
            .more-img {
              height: 12px;
              width: 12px;
              margin-left: 2px;
            }
          }
        }

        .common-coupon-content-text {
          display: flex;
          flex: 1;
          justify-content: space-between;
          flex-direction: column;
          padding: 10px 0 15px 0;
          // max-height: 110Px;
          min-height: 100px;
          box-sizing: border-box;
          .common-coupon-content-t {
          }
          .common-coupon-content-b {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 8px;
          }
          .common-coupon-label {
            height: 16px;
            overflow: hidden;
            line-height: 14px;
            .tag-text {
              border-style: solid;
              border-radius: 3px;
              border-width: 1px;
              margin-left: 1px;
              margin-right: 5px;
              margin-bottom: 5px;
              padding-left: 5px;
              padding-right: 5px;
              font-size: 9px;
              font-family: PingFangSC-Medium;
              font-weight: bold;
              float: left;
            }
            .tag-img {
              width: 100%;
              height: 20px;
              float: left;
            }
          }

          .common-coupon-title {
            color: #222222;
            font-family: PingFangSC-Medium;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            width: calc(100vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .common-coupon-subtitle {
            color: #575757;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            line-height: 12px;
            width: calc(100vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .common-coupon-date {
            color: #666666;
            font-family: PingFangSC-Light;
            font-size: 10px;
            line-height: 10px;
            width: calc(100vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 5px;
          }

          .common-coupon-count {
            color: #666666;
            font-size: 10px;
            line-height: 10px;
            // width: max-content;
            width: calc(100vw - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .common-coupon-detail {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;

            .common-coupon-detail-text {
              color: #000000;
              font-family: PingFangSC-Regular;
              font-size: 10px;
            }
          }
          .discount-title {
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 5px;
            color: #575757;
            .discount-price {
              font-family: SpeedeeApp-Bold;
              color: #d62223;
              font-size: 14px;
              margin: 0 1px;
            }
          }
        }

        .common-coupon-unselected {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          border-radius: 18px;
          border-width: 0.5px;
          border-color: #737373;
          border-style: solid;
        }

        .common-coupon-selected {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        .common-coupon-prepaid {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 48px;
          height: 42px;
        }
      }

      .coupon-divider {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: -7px;
        margin-bottom: -7px;
        .dash-line {
          width: 100%;
          margin-left: 13px;
          margin-right: 13px;
          border-top: 1px dashed rgba(0, 0, 0, 0.05);
        }

        .semi-circle-left {
          border-radius: 0 7px 7px 0;
          height: 12px;
          width: 7px;
          background: #ffffff;
          margin-left: -8px;
        }

        .semi-circle-right {
          border-radius: 7px 0 0 7px;
          height: 12px;
          width: 7px;
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          border-left: 1px solid rgba(163, 89, 89, 0.05);
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          border-right: 1px solid #ffffff;
          background: #ffffff;
          margin-right: -1px;
        }
      }

      .new-avalible-page {
        .semi-circle-left {
          background: #f2f2f2;
        }

        .semi-circle-right {
          background: #f2f2f2;
          border: none;
          margin-right: 0;
        }
      }
    }

    .common-coupon-left {
      position: absolute;
      width: 60%;
      height: 158px;
    }

    .common-coupon-right {
      position: absolute;
      width: 30%;
      height: 123px;
      right: 0px;
    }

    .common-coupon-type-angle {
      position: absolute;
      right: 1px;
      bottom: 28px;
      height: 12px;
      width: 12px;
      background-color: #f2f2f2;

      .common-coupon-type-angle-inside {
        height: 12px;
        width: 12px;
        border-bottom-right-radius: 6px;
        background-color: white;
      }
    }

    .common-coupon-type {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 6px;
      background-color: #f2f2f2;
      padding: 0 5px;
      right: 0;
      bottom: 0;

      .common-coupon-type-image {
        width: 18px;
        height: 18px;
      }
    }
  }
  .common-coupon-content-bottom {
    height: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-bottom-desc {
      font-size: 10px;
      color: #000000;
      margin-left: 12px;
      display: flex;
      align-items: center;
      .common-coupon-detail-arrow {
        width: 12px;
        height: 12px;
      }
    }
    .content-bottom-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      z-index: 10;
      .content-bottom-give {
        // width: 78Px;
        height: 28px;
        border: 1px solid #666666;
        border-radius: 14px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #222222;
        margin-right: 10px;
        padding: 0 15px;
      }
      .content-bottom-cantgive {
        // width: 78Px;
        height: 28px;
        border: 1px solid #eeeeee;
        border-radius: 14px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #999;
        margin-right: 10px;
        padding: 0 15px;
      }
      .content-bottom-use {
        width: 78px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffbc0d;
        border-radius: 14px;
        font-size: 12px;
        color: #222222;
        margin-right: 10px;
      }
      .content-bottom-cantuse {
        width: 78px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeeee;
        border-radius: 14px;
        font-size: 12px;
        color: #999;
        margin-right: 10px;
      }
    }
  }

  .common-coupon-bottom {
    display: flex;
    flex-direction: row;
    height: 8px;
    width: 86%;

    .common-coupon-bottom-img {
      width: 15px;
      height: 8px;
    }

    .common-coupon-bottom-mid {
      flex: 1;
      height: 8px;
    }
  }
}
