@import '../../../styles/variable.less';

.product-detail {
  &-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // TODO
    height: 100vh;
    height: @100vh;
  }

  &-top-bg {
    display: flex;
    width: 100vw;
    max-height: 16rem;
    height: 28%;
    max-height: 256px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    align-items: flex-end;

    &-img {
      margin-bottom: 30px;
      height: 90%;
      height: calc(99% - 30px);
    }
  }

  &-block {
    flex: 1;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    padding-top: 20px;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: -1.428rem;

    &-header {
      padding: 0 20px 6px;
      display: flex;
      flex-direction: row;

      &-title {
        flex: 1;
        font-size: 20px;
        font-weight: @font-weight-bold;
      }
    }

    &-content {
      flex: 1;
      overflow-y: auto;
      padding: 0 20px;

      &-desc {
        font-size: var(--adm-font-size-5);
        line-height: var(--adm-font-size-10);
        font-weight: 300;
        white-space: pre-wrap;
      }

      &-explain {
        margin-top: 20px;
        font-size: var(--adm-font-size-4);
        color: @text-tertiary;

        & > b {
          color: @text-secondary;
        }

        & > p {
          margin: 10px 0;
          white-space: pre-wrap;
        }
      }
    }

    &-footer {
      padding: 15px 20px 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &[data-supportsafearea='true'] {
        padding-bottom: var(--safe-area-inset-bottom);
      }
    }
  }
}
