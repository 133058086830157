@import '../../../../../styles/variable.less';

.itemTitle {
  font-size: var(--adm-font-size-4);
  color: @text-tertiary;
  margin-top: 25px;
}

.comboItemWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  border-radius: 6px;

  &[data-choicestype='1'] {
    padding-bottom: 10px;
  }

  &[data-selected='true']&[data-choicestype='1'] {
    background-color: #f6f6f6;
  }
}

.comboTagImg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 28px;
  height: auto;
}

.comboInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comboImg {
  width: 80px;
  height: 80px;
}

.comboImgFallback {
  width: 100%;
}

.comboName {
  width: 90px;
  height: 32px;
  line-height: 16px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: @text-secondary;

  &[data-default='true'] {
    color: @text-color;
    font-weight: @font-weight-bold;
  }
}

.comboPrice {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: var(--adm-font-size-8);
}

.comboPriceUnit {
  font-size: var(--adm-font-size-3);
  margin-right: 2px;
}

.comboCustomBtn {
  width: 70px;
  height: 24px;
  line-height: 24px;
  border-radius: 6px;
  background-color: @primary-color;
  text-align: center;
  color: #353535;
  font-size: var(--adm-font-size-4);
  font-weight: 500;
  margin-top: 10px;
}

.comboCustomBtnPlaceholder {
  height: 24px;
  margin-top: 10px;
}

.comboSelectImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.choicesWrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.itemChoices {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 15px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.itemChoice {
  &:nth-child(3n + 1) {
    justify-self: start;
  }
  &:nth-child(3n + 3) {
    justify-self: end;
  }
}

.moreWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.moreButton {
  height: 22px;
  width: 75px;
  border: 1px solid rgba(162, 162, 162, 1);
  text-align: center;
  border-radius: 20px;
  line-height: 22px;
  font-size: 12px;
  margin-top: 10px;
  color: @text-color;
  font-weight: bold;
}
