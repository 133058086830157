@import '../../../../styles/variable.less';

.container {
  height: 100vh;
  height: @100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.search {
  padding: 10px 20px;
  border-bottom: 1px solid @disabled-color;
}

.emptyHint {
  margin-top: 35%;
  font-weight: @font-weight-bold;
  text-align: center;
  color: @text-secondary;
  font-size: 1.1428em;
}

.list {
  overflow-y: auto;
  flex: 1;
}
