.search-wrapper {
  width: calc(100vw - 40px);
  margin: 10px 20px 5px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid rgba(153, 153, 153, 0.5);
  color: #666;
  .left {
    display: inline-flex;
    align-items: center;
    width: 90px;
    justify-content: center;
    // padding: 0 15px;
    height: 20px;
    border-right: solid 1px rgba(0, 0, 0, 0.08);
    img {
      margin-left: 2px;
      width: 10px;
      height: 10px;
    }
  }
  .search {
    flex: 1;
    border-radius: 16px;
    .adm-search-bar-input-box {
      border-radius: 16px;
    }
  }
}
