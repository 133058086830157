.product-coupon-empty-view {
  display: flex;
  flex-direction: column;
  align-items: center;

  .product-coupon-empty-text {
    margin-top: 80px;
    font-size: 18px;
    font-family: PingFangSC-Semibold;
    font-weight: bold;
    color: #222222;
  }

  .product-coupon-empty-sub-text {
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC-Light;
    color: #222222;
  }

  .product-coupon-empty-btn-view {
    margin-top: 20px;
    border-radius: 100px;
    background-color: #ffbc0d;
    display: flex;

    .product-coupon-empty-btn-text {
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      color: #222222;
      padding: 10px 40px;
    }
  }
}
