.store-place-list {
  padding-top: 5px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    box-sizing: border-box;
    // height: 73px;
    padding: 18px 20px;
    display: flex;
    &.selected {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 248, 228, 1) 100%);
    }
    .left {
      flex-grow: 1;
      .name {
        color: #222222;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        height: 15px;
        margin-bottom: 10px;
        display: flex;
        .short-name-wrapper {
          flex: 1;
          display: flex;
          .short-name {
            // flex: 1;
          }
          .last-select {
            background-color: #db0007;
            margin-left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            border-top-left-radius: 7.5px;
            border-top-right-radius: 7.5px;
            border-bottom-right-radius: 7.5px;
            height: 15px;
            font-size: 10px;
            font-family: 'PingFangSC-Regular';
            color: #ffffff;
          }
        }
        .tag {
          display: inline-block;
          margin-left: 4px;
          color: #ffffff;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          padding: 0 5px;
          border-radius: 8px 8px 8px 1px;
          background-color: #8e8e8e;
        }
      }
      .address {
        color: #666666;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        height: 12px;
      }
      .business-time {
        display: inline-block;
        margin-top: 5px;
        color: #999;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      }
    }
    .right {
      flex-basis: 66px;
      .detail-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 16px;
        margin-bottom: 9px;
        img {
          width: 20px;
          height: 20px;
        }
        .go {
          display: inline-flex;
          align-items: center;
          span {
            color: #0086d6;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-right: 4px;
          }
          .arrow {
            width: 12px;
            height: 8px;
            // height: 20px;
            transform: rotate(270deg);
          }
        }
      }
      .distance {
        color: #666666;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .adm-ellipsis {
      line-height: 1;
    }
  }
}
