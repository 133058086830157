.usercenter {
  background: #ffffff;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .login {
    background: #ffbc0d;
    width: 145px;
    height: 42px;
    line-height: 42px;
    border-radius: 21px;
    margin-bottom: 50px;
    font-size: 18px;
    color: #222;
    font-family: 'PingFangSC-Semibold';
    font-weight: bold;
    margin-left: 0;
    border: 0 none;
    &::after {
      border: 0 none;
    }
  }
  .pagebg {
    width: 100vw;
    height: 50vw;
    position: relative;
    &-img {
      display: block;
      width: 100vw;
      height: 50vw;
    }
    .pagemess {
      position: absolute;
      bottom: 10px;
      left: 20px;
      span {
        color: #222;
        font-size: 30px;
        font-family: 'PingFangSC-Semibold';
        font-weight: bold;
      }
      .star {
        display: inline-block;
        width: 21px;
        height: 19px;
        position: absolute;
        margin-left: 4px;
      }
    }
  }
  .loginpanel {
    padding: 0 20px;
    .mes {
      display: block;
      font-size: 12px;
      color: #333;
      margin-bottom: 22px;
    }
  }
  .loginmess {
    margin: 10px 0 0;
    // background-image: url(https://img.mcd.cn/gallery/abd3e17c07ba6967.png);
    padding-bottom: 100px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .user_login_avatar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .left {
        display: flex;
        align-items: center;
        margin-right: 20px;
        flex: 1;
        .avatarCon {
          padding-bottom: 20px;
        }
        .avatar {
          display: block;
          width: 55px;
          height: 55px;
          border-radius: 27.5px;
          overflow: hidden;
          margin-right: 10px;
          user-select: none;
        }
        .name {
          font-size: 20px;
          color: #222;
          font-family: 'PingFangSC-Semibold';
          font-weight: bold;
          margin-right: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .accountcon {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #333;
          padding-bottom: 20px;
          .core-icon {
            width: 14px;
            margin-right: 8px;
          }
          .fen {
            display: flex;
            align-items: baseline;
            .account {
              font-size: 20px;
              font-family: SpeedeeApp-Bold;
              // margin-right: 3Px;
              background-image: linear-gradient(
                90deg,
                #910063 0%,
                #990059 4%,
                #b60035 17%,
                #ca001c 30%,
                #d7000c 42%,
                #db0007 51%,
                #e8720a 100%
              );
              -webkit-background-clip: text;
              color: transparent;
            }
          }
          .account_red_arrow {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .member-panel {
    display: flex;
    background-color: #fff;
    margin: 0 20px;
    padding: 14px 0;
    min-height: 44px;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 15px;
    margin-bottom: 30px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    .member-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 15px;
      // flex-direction: column;
      // width: 160px;
      overflow: hidden;
      &:not(:last-child) {
        border-right: 0.5px solid rgba(0, 0, 0, 0.1);
      }
      img {
        width: 42px;
        height: 42px;
      }
      .title-wrapper {
        .member-item-title {
          display: block;
          font-family: PingFangSC-Regular;
          color: #222;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 3px;
        }
        .member-item-subTitle {
          display: block;
          font-family: PingFangSC-Regular;
          color: #666666;
          font-weight: 400;
          font-size: 11px;
          letter-spacing: -1px;
          // width: 120px;
        }
        // .member-item-subNum {
        //   color: #db0007;
        //   font-size: 10px;
        //   font-weight: bold;
        // }
      }
    }
  }
  .entry {
    margin: 0 30px;
    position: relative;
    top: -120px;
    .entry-item {
      height: 46px;
      line-height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      span {
        font-size: 17px;
        color: #222;
        font-family: 'PingFangSC-Semibold';
        font-weight: bold;
      }
      img {
        display: block;
        width: 16px;
        height: 16px;
        overflow: hidden;
      }
    }
  }
}
