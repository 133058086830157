.home-page {
  background-color: #f0f0f0;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .banner {
    min-height: 100px;
    .item {
      width: 100%;
      height: 234px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .user {
    position: relative;
    margin: 0 auto;
    margin-top: -83px;
    width: 315px;
    height: 83px;
  }
  .big-category {
    margin-top: -24px;
    .category-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        height: calc(((100vw - 30px - 4px) / 2) * 164 / 169);
        width: calc((100vw - 30px - 4px) / 2);
        margin-right: 7px;
        &:last-child {
          margin-right: 0px;
        }
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        z-index: 2;
        .home_big_item_title_view {
          display: flex;
          margin-top: 15px;
          flex-direction: row;
          align-items: center;

          .home_big_item_title {
            margin-left: 22px;
            font-size: 20px;
            height: 24px;
            line-height: 24px;
            font-family: 'PingFangSC-Semibold';
            font-weight: bold;
            color: rgba(0, 0, 0, 1);
          }
          .home_big_item_icon {
            width: 20px;
            height: 20px;
            margin-left: 2px;
          }
        }
        .home_big_item_subTitle {
          margin-left: 22px;
          margin-top: 1px;
          font-size: 12px;
          height: 34px;
          line-height: 34px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
  .activity {
    margin-top: 20px;
    .item {
      width: calc(~'100vw - 30px');
      // height: 148px;
      margin: 0 15px 10px;

      img {
        width: 100%;
        // height: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }
  }
}
