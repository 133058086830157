.edit-address-container {
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 44px;
  .content {
    height: calc(~'100vh - 134px');
    width: 100vw;

    .form-wrapper {
      .adm-form-item-label {
        color: #999;
        font-size: 14px;
      }
      .adm-form-item-child-inner {
        color: #999999;
        font-weight: 300;
        font-size: 14px;
        input::placeholder {
          font-size: 14px;
          color: #999999;
          font-weight: 300;
        }
      }

      .adm-list-item-content {
        border-top: none;
        position: relative;
        .adm-list-item-content-extra {
          position: absolute;
          right: 10px;
        }
      }
      .adm-list-item-content-main {
        border-top: var(---border-inner);
        // padding-right: 120px;
      }
      .adm-form-item-has-error {
        .adm-input .adm-input-element {
          color: #db0007 !important;
        }
      }
      .full-name {
        .adm-list-item-content-main {
          padding-right: 120px;
        }
      }

      .adm-radio-custom-icon {
        display: inline-flex;
        align-items: center;
      }

      .radio-icon {
        box-sizing: border-box;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #d5d5d9;
        &.selected {
          border: none;
          background-color: var(--adm-color-primary);
          & > span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
    }
    // antd
    .adm-form-item.adm-form-item-horizontal.adm-list-item {
      --prefix-width: 80px;
      --align-items: center;
    }
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    button:first-child {
      margin-right: 20px;
      flex: 3;
    }
    button:last-child {
      flex: 4;
    }
  }
}
