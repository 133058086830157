.address-list-container {
  background-color: #f9f9f9;
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 44px;
  padding-bottom: 90px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .address-content {
    padding: 10px 15px 0;
    width: calc(~'100vw - 30px');
  }
  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .desc {
      margin-top: -50px;
      height: 22px;
      line-height: 22px;
      color: #222222;
      font-weight: 300;
      font-size: 16px;
    }
    .btn {
      margin-top: 30px;
      width: 195px;
      height: 42px;
      font-size: 18px;
    }
  }
}
