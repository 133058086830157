@import '../../../../../styles/variable.less';

.specialTitle {
  font-size: var(--adm-font-size-4);
  color: @text-tertiary;
  padding-top: 25px;
}

.specialWrapper {
  display: flex;
  flex-direction: row;

  &[data-single='true'] {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-row-gap: 15px;
  }

  &[data-more='true'] {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-row-gap: 15px;
  }
}

.specialItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 12px;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  &:nth-child(4n + 1) {
    margin-left: 0;
  }
}

.specialItemContent {
  margin-top: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
  font-weight: 400;

  &[data-checked='true'] {
    border: 2px solid rgba(255, 188, 13, 1);
    color: #222222;
    font-weight: 700;
  }
}

.specialItemImg {
  height: 15px;
  margin-right: 4px;
  flex-shrink: 0;
}

.specialItemSpread {
  color: #cbaa70;
  font-size: var(--adm-font-size-5);
  flex-shrink: 0;
}
