.store-info-container {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  .base-info {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 20px;
    background-image: url(https://img.mcd.cn/mini/main/images/confirm_map.png);
    background-size: 100%;
    .left {
      flex-grow: 1;
      .name {
        color: #222222;
        font-weight: 600;
        font-size: 18px;
      }
      .address {
        color: #222222;
        font-weight: 300;
        font-size: 14px;
      }
    }
    .right {
      position: relative;
      flex-basis: 50px;
      margin-left: 15px;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .btn-wrapper {
        position: absolute;
        left: 50%;
        top: -14px;
        width: 100%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        .btn {
          height: 20px;
          line-height: 20px;
          color: #7e5122;
          font-weight: 500;
          font-size: 10px;
          padding: 0 5px;
          border-radius: 10px;
          background-color: #ffe1a0;
          white-space: nowrap;
          transform: scale(0.8);
        }
      }
    }
  }

  .eat-type-wrapper {
    display: flex;
    padding: 0 20px;
    .item {
      position: relative;
      flex: 1;
      margin-right: 10px;
      &.selected {
        .item-content {
          padding: 11px 0;
          border: 2px solid #ffbc0d;
        }
      }
      .item-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 0;
        border: 1px solid #737373;
        border-radius: 5px;
        .icon {
          width: 35px;
          height: 35px;
          object-fit: cover;
          margin-left: 15px;
          margin-right: 10px;
        }
        .eat-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title {
            color: #222222;
            font-weight: 600;
            font-size: 16px;
          }
          .subTitle {
            color: #999999;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }

      .selected {
        position: absolute;
        right: -6px;
        top: -6px;
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.multiple {
        .item-content {
          flex-direction: column;
          .icon {
            margin-right: 0;
            margin-bottom: 10px;
          }
          .eat-info {
            align-items: center;
          }
        }
      }
      &.single {
        flex-basis: calc(~'50vw - 40px ');
        flex-grow: 0;
        .icon {
          margin-left: 10px;
        }
        .eat-info {
          flex: 1;
          .title,
          .subTitle {
            width: 100%;
          }
        }
      }
    }
  }
}
