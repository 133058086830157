.multiWrapper {
  margin: 20px 0 0 0;
  height: 58px;
  display: flex;
  position: relative;
}

.multiImage {
  position: absolute;
  left: -5px;
  top: 0;
  width: 58px;
  height: 58px;
  z-index: 1;
}

.multiBack {
  position: absolute;
  left: 33px;
  top: 10px;
  width: 86px;
  height: 35px;
  z-index: 0;
}

.multiRight {
  position: absolute;
  left: 115px;
  top: 35px;
  right: 10px;
  height: 4px;
  background-size: contain;
}

.singleImageWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 15px;
}

.singleImage {
  width: 102px;
  height: 20px;
}

.singleBarImg {
  flex: 1;
  height: 2px;
  margin-bottom: 2px;
}
