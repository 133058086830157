.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.restButton {
  font-size: var(--adm-font-size-8) !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  margin-right: 16px;
}

.addCartButton {
  font-size: var(--adm-font-size-8);
  flex: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  height: 42px;
}

.addCartBtnPrice {
  font-size: var(--adm-font-size-10);
}

.addCartBtnPriceNum {
  font-size: 1.428rem;
}
.addCartBtnPriceLast {
  font-size: var(--adm-font-size-8);
}

.addCartButtonTxt {
  margin-left: 6px;
  font-size: var(--adm-font-size-8);
}
