.curcardlist-page {
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  .product-coupon-view {
    .coupon-item {
      margin-bottom: 10px;
      .top-wrapper {
        padding: 0px 15px;
        .top-banner {
          height: 45px;
          width: 100%;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          .header {
            width: 100%;
            height: 37px;
            display: inline-flex;
            align-items: center;
            img {
              height: 37px;
              width: 105px;
              -webkit-flex-shrink: 0;
              -ms-flex-negative: 0;
              flex-shrink: 0;
              margin-left: 5px;
            }
            .split-line {
              width: 1px;
              height: 12px;
              margin: 0 8px;
              background: #975401;
              flex-shrink: 0;
              opacity: 0.3;
            }
            .total-text {
              font-size: 11px;
              line-height: 16px;
              color: #975401;
            }
          }
        }
      }
      .coupon-wrapper {
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 6px;
        background-color: #f2f2f2;
        // width: calc(~'100vw -30px');
        margin: 0 15px;
        margin-top: -8px;
      }
    }
  }
}
