.home_order_detail_info_bg {
  background-color: #eeeeee;
  position: relative;

  .home_order_detail_info_view {
    background-color: #fffcf4;
    margin-left: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 10px;
    height: 55px;
    width: calc(100vw - 30px);
    display: flex;
    flex-direction: row;
    align-items: center;

    .home_order_detail_left_view {
      margin-left: 20px;
      display: flex;
      flex-direction: row;

      .home_order_detail_time_bg {
        display: flex;
        flex-direction: column;
        align-items: center;

        .home_order_detail_time_view {
          font-size: 30px;
          font-family: 'Futura-Medium';
          color: #222222;
          height: 30px;
          line-height: 30px;
          z-index: 1;
        }

        .home_order_detail_time_yellow_line {
          margin-top: -8px;
          background-color: #ffbc0d;
          border-bottom-left-radius: 6px;
          border-top-right-radius: 6px;
          height: 8px;
          width: 100%;
        }
      }

      .home_order_detail_tag {
        margin-top: 1px;
        height: 15px;
        line-height: 15px;
        border-style: solid;
        border-color: #000000;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-width: 1px;
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 10px;
        color: rgba(26, 26, 26, 100);
        font-family: 'PingFangSC-Medium';
      }
    }

    .home_order_detail_right_view {
      margin-left: 5px;
      margin-right: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      .home_order_detail_right_desc_bg {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 1;

        .home_order_detail_right_desc_title {
          font-family: 'PingFangSC-Semibold';
          font-size: 13px;
          color: #222222;
          height: 17px;
          line-height: 17px;

          overflow: hidden;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .home_order_detail_right_desc_detail {
          font-family: 'PingFangSC-Regular';
          font-size: 10px;
          color: #666666;
          height: 14px;
          line-height: 14px;
          margin-top: 1px;

          overflow: hidden;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }

      .home_order_detail_right_arrow {
        width: 16px;
        height: 16px;
        margin-left: 6px;
      }
    }
  }
}
